export const toPascalCase = (value: string): string => {
  if (!value) {
    return '';
  }

  return value
    .trim()
    .replace(/(\s)+/g, '$1')
    .split(' ')
    .map((word) => word[0].toUpperCase().concat(word.slice(1)))
    .join('');
};
