import { ApiService } from "./ApiService";
import { Address } from "../models/Address";
import { REACT_APP_ADHESION_API_URL } from "../constants/env";

export class AddressService {
  private static instance: AddressService;
  private readonly api: ApiService;

  private constructor() {
    this.api = new ApiService(REACT_APP_ADHESION_API_URL);
  }

  public static getInstance(): AddressService {
    if (!this.instance) {
      this.instance = new AddressService();
    }

    return this.instance;
  }

  async searchAddress(adresse: string): Promise<Address[]> {
    return this.api
      .get<Address[]>("/Adresse", { params: { input: adresse } })
      .then((data) => data);
  }
}
