import {
  WizzardStep,
  WizzardContent,
  WizzardStepWrapperProps,
  useWizzard,
} from "../../../../components/Wizzard";
import { Block, InputText, Text } from "@roole/components-library";
import { useEffect, useState } from "react";
import styles from "./Payment.module.scss";
import { RxService } from "../../../../services/RxService";
import { ICommand } from "../../../../models/Command";
import { useForm } from "react-hook-form";
import { REACT_APP_CONDITION_GENERALES_VENTE_URL } from "../../../../constants/env";
import { RxProduct } from "../../RxProduct/RxProduct";

const rxService = RxService.getInstance();

const Payment: React.FC<WizzardStepWrapperProps> = () => {
  const { setNavBtnsConfig, setLoading } = useWizzard();
  const [cgvAccepted, setCgvAccepted] = useState(false);
  const [fetchingStatus, setFetchingStatus] =
    useState<"fetching" | "succeeded" | "error">("fetching");

  const [commandeRxId, setCommandeRxId] = useState<string | null>(null);
  const { register, reset } = useForm<ICommand>();

  useEffect(() => {
    setNavBtnsConfig({
      nextBtn: {
        disabled: !cgvAccepted,
        label: "Procéder au paiement",
        handler: async () => {
          if (!commandeRxId) return;

          const res = await rxService.confirmCommande(commandeRxId);
          location.href = res.urlPaiement;
          setLoading(true);
        },
      },
    });
  }, [cgvAccepted, setCgvAccepted]);

  useEffect(() => {
    const commandeRxId = localStorage.getItem("commandeRxId");

    setCommandeRxId(commandeRxId);

    if (commandeRxId) {
      setFetchingStatus("fetching");

      rxService
        .getCommande(commandeRxId)
        .then((data) => {
          reset({
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            civilite: data.civilite as any,
            nom: data.nom,
            prenom: data.prenom,
            telephoneMobile: data.telephoneMobile,
            email: data.email,
            adresse: {
              rue: data.adresse?.rue,
              complement: data.adresse?.complement,
              codePostal: data.adresse?.codePostal,
              ville: data.adresse?.ville,
            },
            vehicule: {
              immatriculation: data.vehicule?.immatriculation,
            },
          });

          setFetchingStatus("succeeded");
        })
        .catch(() => {
          setFetchingStatus("error");
        });
    }
  }, []);

  const defaultValue = "Chargement...";

  return (
    <WizzardStep
      aside={
        <WizzardContent aside>
          <RxProduct headband={false} />
        </WizzardContent>
      }
    >
      <WizzardContent>
        <RxProduct headband={true} />

        <Block>
          <Text textVariant="heading-4">Vérifiez vos informations</Text>

          <div className={styles.FormRow}>
            <InputText
              {...register("civilite")}
              defaultValue={defaultValue}
              disabled
            />
            <div className={styles.empty} />
          </div>
          <div className={styles.FormRow}>
            <InputText
              {...register("nom")}
              label="Nom"
              defaultValue={defaultValue}
              disabled
            />
            <InputText
              {...register("prenom")}
              label="Prénom"
              defaultValue={defaultValue}
              disabled
            />
          </div>
          <div className={styles.FormRow}>
            <InputText
              {...register("email")}
              label="Email"
              defaultValue={defaultValue}
              disabled
            />
            <InputText
              {...register("telephoneMobile")}
              label="Téléphone"
              defaultValue={defaultValue}
              disabled
            />
          </div>

          <InputText
            className={styles.address}
            {...register("adresse.rue")}
            label="Adresse"
            defaultValue={defaultValue}
            disabled
          />

          <div className={styles.FormRow}>
            <InputText
              {...register("adresse.ville")}
              label="Ville"
              defaultValue={defaultValue}
              disabled
            />
            <InputText
              {...register("adresse.codePostal")}
              label="Code Postal"
              defaultValue={defaultValue}
              disabled
            />
          </div>

          <InputText
            {...register("adresse.complement")}
            label="Complément d'adresse"
            defaultValue={defaultValue}
            disabled
          />

          <div className={styles.FormRow}>
            <InputText
              {...register("vehicule.immatriculation")}
              label="Plaque d'immatriculation"
              defaultValue={defaultValue}
              disabled
            />
            <div className={styles.empty} />
          </div>
        </Block>

        {fetchingStatus === "succeeded" && (
          <div
            id={styles.acceptCgv}
            style={{ display: "flex", alignItems: "center" }}
          >
            <input
              onChange={(e) => setCgvAccepted(e.currentTarget.checked)}
              checked={cgvAccepted}
              type="checkbox"
              id="acceptCgv"
            />
            <label htmlFor="acceptCgv">
              J&apos;accepte les{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={REACT_APP_CONDITION_GENERALES_VENTE_URL}
              >
                Conditions générales de ventes
              </a>
            </label>
          </div>
        )}
      </WizzardContent>
    </WizzardStep>
  );
};

export default Payment;
