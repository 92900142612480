import { FC, Fragment } from 'react';
import { Navigate } from 'react-router-dom';

import { UniversignStatus } from '../../hooks/useESignature/universign-types';
import { useSubscription } from '../../hooks';

interface CongratulationsStepGuardProps {
  redirectTo?: string;
}

export const CongratulationsStepGuard: FC<CongratulationsStepGuardProps> = ({
  redirectTo = '/',
  children,
}) => {
  const { eSignatureStatus } = useSubscription();

  return eSignatureStatus === UniversignStatus.SIGNED ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Navigate to={redirectTo} replace={true} />
  );
};
