import { Text } from "@roole/components-library";
import { CSSProperties, isValidElement, ReactNode } from "react";
import styled from "styled-components";
import { mediaQueries, spaces } from "../../../constants/theme/variables";
import Stepper from "../WizzardStepper";

// HeaderTitles
export type HeaderTitlesProps = {
  dark: boolean;
  isMobileAndTablet: boolean;
  isStep: boolean;
  title: ReactNode | string;
  subtitle: ReactNode | string;
};
const HeaderTitlesWrapper = styled.div<{ dark: boolean }>(({ dark }) => {
  return {
    padding: spaces.m,
    color: dark ? "white" : undefined,
  };
});
export const HeaderTitles: React.FC<HeaderTitlesProps> = ({
  dark,
  isMobileAndTablet,
  isStep,
  title,
  subtitle,
}) =>
  (!isMobileAndTablet || !isStep) && !title && !subtitle ? (
    <></>
  ) : (
    <HeaderTitlesWrapper dark={dark}>
      {
        <Text textVariant="display-2" as="h1" id="wizardStepTitle">
          {title}
        </Text>
      }

      {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
    </HeaderTitlesWrapper>
  );

// Subtitle
export type SubtitleProps = { textStyle?: CSSProperties };
export const Subtitle: React.FC<SubtitleProps> = ({ children, textStyle }) => {
  return isValidElement(children) ? (
    <div>{children}</div>
  ) : (
    <Text style={textStyle} textVariant="body" as="p">
      {children}
    </Text>
  );
};

// MobileAndTabletStepper
type MobileAndTabletStepperProps = {
  label: string;
  step: number;
  countSteps: number;
};
const MobileAndTabletStepperWrapper = styled.div`
  background-color: #ffd100;
  padding-top: 7px;
  padding-bottom: 7px;
  text-align: center;
`;
export const MobileAndTabletStepper: React.FC<MobileAndTabletStepperProps> = ({
  step,
  countSteps,
  label,
}) => {
  return (
    <MobileAndTabletStepperWrapper>
      <Text textVariant="body-em">
        Étape {step + 1}/ {countSteps} - {label}
      </Text>
    </MobileAndTabletStepperWrapper>
  );
};

// StickedHeader
export const StickedHeader = styled.header<{ sticked?: boolean }>(
  ({ sticked }) => {
    const position = sticked ? "sticky" : undefined;

    return {
      position,
      left: 0,
      right: 0,
      top: -1,

      [mediaQueries.mobileAndTablet]: {
        backgroundColor: "white",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
      },
      [mediaQueries.desktop]: {
        gridColumn: "span 3",
        paddingTop: spaces.s,
        marginRight: spaces.m,
        marginLeft: spaces.m,
      },
      zIndex: 1000,
    };
  }
);

// DesktopHeader
const StyledDesktopHeader = styled.div((_) => {
  _;

  return {
    padding: `${spaces.s} 0`,
    marginLeft: spaces.m,
    marginRight: spaces.m,
    // backgroundColor: "white",
    color: "white",
    borderRadius: 12,
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 16px 0px;",
  };
});
export const DesktopHeader = () => (
  <StyledDesktopHeader>
    <Stepper />
  </StyledDesktopHeader>
);
