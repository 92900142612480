import { Action, ActionWithProps } from '../../helpers';
import { Subscription } from '../../models';

export enum AuthActionType {
  LOGIN = 'LOGIN',
  LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED',
  LOGIN_FAILED = 'LOGIN_FAILED',
}

export type AuthAction =
  | Action<AuthActionType.LOGIN>
  | ActionWithProps<
      AuthActionType.LOGIN_SUCCEEDED,
      { details: Partial<Subscription> }
    >
  | ActionWithProps<AuthActionType.LOGIN_FAILED, { error: string }>;
