import { ComponentProps, FC } from 'react';
import { Text } from '@roole/components-library';

import { CommonProps } from '../../helpers';

interface HtmlTextProps extends CommonProps {
  text?: string;
  variant?: ComponentProps<typeof Text>['textVariant'];
}

export const HtmlText: FC<HtmlTextProps> = ({
  className,
  text,
  variant,
  ...props
}) => {
  return text ? (
    <Text
      className={className}
      dangerouslySetInnerHTML={{ __html: text }}
      textVariant={variant}
      as="p"
      {...props}
    />
  ) : null;
};
