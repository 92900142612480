import { Action, ActionWithProps } from '../../helpers';
import { BankDetails, Subscription } from '../../models';
import { UniversignStatus } from '../../hooks/useESignature/universign-types';

export enum SubscriptionActionType {
  GET_STEP_DETAILS = 'GET_STEP_DETAILS',
  GET_STEP_DETAILS_SUCCEEDED = 'GET_STEP_DETAILS_SUCCEEDED',
  GET_STEP_DETAILS_FAILED = 'GET_STEP_DETAILS_FAILED',
  UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS',
  UPDATE_BANK_DETAILS_SUCCEEDED = 'UPDATE_BANK_DETAILS_SUCCEEDED',
  UPDATE_BANK_DETAILS_FAILED = 'UPDATE_BANK_DETAILS_FAILED',
  E_SIGNATURE_COMPLETED = 'E_SIGNATURE_COMPLETED',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  DOWNLOAD_FILE_SUCCEEDED = 'DOWNLOAD_FILE_SUCCEEDED',
  DOWNLOAD_FILE_FAILED = 'DOWNLOAD_FILE_FAILED',
}

export type SubscriptionAction =
  | Action<SubscriptionActionType.GET_STEP_DETAILS>
  | ActionWithProps<
      SubscriptionActionType.GET_STEP_DETAILS_SUCCEEDED,
      { details: Partial<Subscription> }
    >
  | ActionWithProps<
      SubscriptionActionType.GET_STEP_DETAILS_FAILED,
      { error: string }
    >
  | Action<SubscriptionActionType.UPDATE_BANK_DETAILS>
  | ActionWithProps<
      SubscriptionActionType.UPDATE_BANK_DETAILS_SUCCEEDED,
      { details: BankDetails }
    >
  | ActionWithProps<
      SubscriptionActionType.UPDATE_BANK_DETAILS_FAILED,
      { error: string }
    >
  | ActionWithProps<
      SubscriptionActionType.E_SIGNATURE_COMPLETED,
      { status: UniversignStatus }
    >
  | Action<SubscriptionActionType.DOWNLOAD_FILE>
  | Action<SubscriptionActionType.DOWNLOAD_FILE_SUCCEEDED>
  | ActionWithProps<
      SubscriptionActionType.DOWNLOAD_FILE_FAILED,
      { error: string }
    >;
