import { Button, Text } from "@roole/components-library";
import { useWizzard } from "../../../../../../components/Wizzard";
import styles from "./ProtectNow.module.scss";

export const ProtectNow: React.FC = () => {
  const { nextStep } = useWizzard();

  return (
    <div className={styles.ProtectNow}>
      <div className={styles.titleblock}>
        <Text textVariant="display-1" as="h1" className={styles.title}>
          <span className={styles["title--marked"]}>
            Protégez votre véhicule
          </span>
          <br />
          dès maintenant
        </Text>

        <Button
          variant="primary"
          iconBeforeText
          icon="arrow-right"
          customAttributes={{
            className: styles.buyBtn,
          }}
          onClick={() => {
            nextStep();
          }}
        >
          <Text textVariant="body-em">Acheter la balise</Text>
        </Button>
      </div>

      <img src="/assets/women-driver.png" />
    </div>
  );
};
