import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from '@roole/components-library';
import '@roole/components-library/build/cjs/index.css';
import 'react-loading-skeleton/dist/skeleton.css';

import { App } from './App';
import './index.scss';

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
