import {
  Block,
  NEXT_BTN,
  Text,
  useWizard,
  WizardNav,
  WizardStep,
} from '@roole/components-library';
import { useNavigate } from 'react-router-dom';

import { SubscriptionStep } from '../SubscriptionWizard/SubscriptionStep';
import { trackClickHandler } from '../../helpers';
import { useSubscription } from '../../hooks';

export const CongratulationsStep: WizardStep = () => {
  const {
    loading,
    downloading,
    supportPhoneNumber,
    subscription: { credentials },
  } = useSubscription();
  const { submitStep } = useWizard();
  const navigate = useNavigate();
  const pending = loading || downloading;

  const nextStepHandler = () => {
    if (credentials?.accountURL) {
      trackClickHandler('ComptePersonnel');
      submitStep(null, () =>
        navigate(credentials.accountURL, { replace: true })
      );
    }
  };

  return (
    <SubscriptionStep
      title="Félicitations"
      subtitle="Finalisez pour rouler en toute sérénité !"
      supportPhoneNumber={supportPhoneNumber}
    >
      <Block>
        <Text textVariant="heading-4" as="h2">
          Votre compte personnel
        </Text>
        <Text textVariant="body" as="p">
          {`Ce compte vous permet de consulter vos garanties, déclarer vos sinistres, suivre votre carnet d'entretien et accéder à l'ensemble de vos bons plans.`}
        </Text>
        <WizardNav
          buttons={NEXT_BTN}
          nextStepLabel="Créer mon compte personnel"
          nextStepHandler={nextStepHandler}
          disabled={pending}
        />
      </Block>
    </SubscriptionStep>
  );
};
