import { useWizzard } from "../Wizzard.contexte";
import { StepperWrapper, Step, StepPoint } from "./WizzardStepper.styled";
import { StepperProps } from "./WizzardStepper.types";

const Stepper: React.FC<StepperProps> = () => {
  const { step, stepsConfig } = useWizzard();

  return (
    <>
      <StepperWrapper>
        {stepsConfig.map((config) => {
          const done = config.step < step;

          return (
            <Step key={config.label} done={done}>
              <StepPoint done={done} />
              <div>{config.label}</div>
            </Step>
          );
        })}
      </StepperWrapper>
    </>
  );
};

export default Stepper;
