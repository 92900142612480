import { ESignatureAction, ESignatureActionType } from './ESignatureActions';
import { UniversignStatus } from './universign-types';

export interface ESignatureState {
  loading: boolean;
  loaded: boolean;
  embeding: boolean;
  embeded: boolean;
  signing: boolean;
  status: UniversignStatus;
  completed: boolean;
  error: string | null;
}

export const initialESignatureState: ESignatureState = {
  loading: false,
  loaded: false,
  embeding: false,
  embeded: false,
  signing: false,
  status: UniversignStatus.PENDING_VALIDATION,
  completed: false,
  error: null,
};

export const eSignatureReducer = (
  state: ESignatureState,
  action: ESignatureAction
): ESignatureState => {
  switch (action.type) {
    case ESignatureActionType.LOAD_SCRIPT:
      return { ...state, loading: true, error: null };
    case ESignatureActionType.LOAD_SCRIPT_SUCCEEDED:
      return { ...state, loading: false, loaded: true };
    case ESignatureActionType.EMBED_IFRAME:
      return { ...state, embeding: true, error: null };
    case ESignatureActionType.EMBED_IFRAME_SUCCEEDED:
      return { ...state, embeding: false, embeded: true };
    case ESignatureActionType.E_SIGNATURE:
      return { ...state, signing: true, error: null };
    case ESignatureActionType.E_SIGNATURE_COMPLETED:
      return {
        ...state,
        signing: false,
        status: action.status,
        completed: true,
      };
    case ESignatureActionType.E_SIGNATURE_FAILED:
      return {
        ...state,
        loading: false,
        embeding: false,
        signing: false,
        error: action.error,
      };
    default:
      return { ...initialESignatureState };
  }
};
