import {
  WizzardStep,
  WizzardContent,
  WizzardExtraStepWrapperProps,
  useWizzard,
} from "../../../../components/Wizzard";
import { Block, Text } from "@roole/components-library";
import { useEffect } from "react";
import styles from "./Finisher.module.scss";
import { DownloadApp } from "./DownloadApp/DownloadApp";

const Finisher: React.FC<WizzardExtraStepWrapperProps> = () => {
  const { setNavBtnsConfig } = useWizzard();

  useEffect(() => {
    localStorage.removeItem("commandeRxId");

    setNavBtnsConfig({
      nextBtn: { hidden: true },
      prevBtn: { hidden: true },
    });
  }, []);

  return (
    <WizzardStep
      aside={
        <WizzardContent aside>
          <DownloadApp />
        </WizzardContent>
      }
    >
      <WizzardContent>
        <Block className={styles.Finisher}>
          <Text textVariant="heading-3" as="h3">
            Votre commande est confirmée
          </Text>
          <Text textVariant="body">
            Un email de confirmation vous a été envoyé, merci de vérifier vos
            courriers indésirables ou spams.
            <br />
            Vous recevrez votre balise Rx sous un délai de 15 jours.
            <br />
            <br /> Suivez le statut de votre commande sur l&apos;application
            Roole Premium.
          </Text>
        </Block>
      </WizzardContent>
    </WizzardStep>
  );
};

export default Finisher;
