import { FC, Fragment } from 'react';
import { Navigate } from 'react-router-dom';

import { AppRoute } from '../../AppRoute';
import { useSubscription } from '../../hooks';
import { UniversignStatus } from '../../hooks/useESignature/universign-types';

interface ESignatureStepGuardProps {
  redirectTo?: string;
}

export const ESignatureStepGuard: FC<ESignatureStepGuardProps> = ({
  redirectTo = `../${AppRoute.BANK_DETAILS}`,
  children,
}) => {
  const { updated, eSignatureStatus } = useSubscription();

  if (updated && eSignatureStatus === UniversignStatus.PENDING_VALIDATION) {
    return <Fragment>{children}</Fragment>;
  }

  return (
    <Navigate
      to={
        eSignatureStatus === UniversignStatus.SIGNED
          ? `../${AppRoute.CONGRATULATIONS}`
          : redirectTo
      }
      replace={true}
    />
  );
};
