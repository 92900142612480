import { FC } from 'react';
import { Block, Button, Text } from '@roole/components-library';

import { DarkBlock } from './DarkBlock';
import { classNames, CommonProps, trackClickHandler } from '../../helpers';
import styles from './QuestionBlock.module.scss';

interface QuestionBlockProps extends CommonProps {
  phoneNumber?: string;
  dark?: boolean;
}

export const QuestionBlock: FC<QuestionBlockProps> = ({
  className,
  phoneNumber,
  dark = false,
  ...props
}) => {
  const Root = dark ? DarkBlock : Block;

  return (
    <Root
      className={classNames(styles['question-block'], className)}
      {...props}
    >
      {!dark && (
        <Text
          className={styles['question-block__title']}
          textVariant="body"
          as="p"
        >
          Une question ?
        </Text>
      )}
      <Text textVariant="ui-1" as="p">
        {dark
          ? 'Une question ? À votre écoute du lundi au vendredi de 9h à 18h'
          : 'Nous sommes à votre écoute du lundi au vendredi, de 9h à 18h'}
      </Text>
      {phoneNumber && (
        <Button
          customAttributes={{
            className: styles['question-block__cta'],
            role: 'button',
          }}
          variant={dark ? 'outlined-dark' : 'secondary'}
          href={`tel:${phoneNumber}`}
          linkComponent="a"
          icon="phone"
          iconBeforeText={!dark}
          size="small"
          onClick={trackClickHandler('Telephone')}
        >
          {dark ? 'Appeler' : phoneNumber}
        </Button>
      )}
      {!dark && phoneNumber && (
        <Text
          className={styles['question-block__bottom-phrase']}
          textVariant="ui-1"
          as="p"
        >
          Appel gratuit
        </Text>
      )}
    </Root>
  );
};
