import {
  SubscriptionAction,
  SubscriptionActionType,
} from './SubscriptionActions';
import { Subscription, SubscriptionKeys } from '../../models';
import { UniversignStatus } from '../../hooks/useESignature/universign-types';
import { DEFAULT_CONFIG } from '../../services';

export interface SubscriptionState {
  subscription: Partial<Subscription>;
  loading: boolean;
  updating: boolean;
  downloading: boolean;
  updated: boolean;
  eSignatureStatus: UniversignStatus;
  error: string | null;
}

export const initialSubscriptionState: SubscriptionState = {
  subscription: {
    vosCoordonneesBancaires: {
      directDebit: 13.95,
      holder: '',
      iban: '',
    },
    credentials: {
      accountURL: DEFAULT_CONFIG.support.accountURL,
    },
  },
  loading: false,
  updating: false,
  downloading: false,
  updated: true,
  eSignatureStatus: UniversignStatus.PENDING_VALIDATION,
  error: null,
};

const mergeSubscription = (
  dest: Partial<Subscription>,
  src: Partial<Subscription>
): Partial<Subscription> =>
  (Object.keys(src) as SubscriptionKeys[]).reduce(
    (subscription, key) =>
      Object.assign(subscription, {
        [key]: { ...dest[key], ...src[key] },
      }),
    { ...dest }
  );

export const subscriptionReducer = (
  state: SubscriptionState,
  action: SubscriptionAction
): SubscriptionState => {
  switch (action.type) {
    case SubscriptionActionType.GET_STEP_DETAILS:
      return { ...state, loading: true, error: null };
    case SubscriptionActionType.GET_STEP_DETAILS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        subscription: mergeSubscription(state.subscription, action.details),
      };
    case SubscriptionActionType.GET_STEP_DETAILS_FAILED:
      return { ...state, loading: false, error: action.error };
    case SubscriptionActionType.UPDATE_BANK_DETAILS:
      return { ...state, updating: true, error: null };
    case SubscriptionActionType.UPDATE_BANK_DETAILS_SUCCEEDED:
      return {
        ...state,
        updating: false,
        updated: true,
        subscription: mergeSubscription(state.subscription, {
          vosCoordonneesBancaires: action.details,
        }),
      };
    case SubscriptionActionType.UPDATE_BANK_DETAILS_FAILED:
      return { ...state, updating: false, error: action.error };
    case SubscriptionActionType.E_SIGNATURE_COMPLETED:
      return { ...state, eSignatureStatus: action.status };
    case SubscriptionActionType.DOWNLOAD_FILE:
      return { ...state, downloading: true, error: null };
    case SubscriptionActionType.DOWNLOAD_FILE_SUCCEEDED:
      return { ...state, downloading: false };
    case SubscriptionActionType.DOWNLOAD_FILE_FAILED:
      return { ...state, downloading: false, error: action.error };
    default:
      return { ...initialSubscriptionState };
  }
};
