import { FC } from "react";
import { Logo } from "@roole/components-library";

import { classNames, CommonProps, trackClickHandler } from "../../helpers";
import { DEFAULT_CONFIG } from "../../services";
import styles from "./Header.module.scss";
import { REACT_APP_SITEWEB_VOB2C_URL } from "../../constants/env";

interface HeaderProps extends CommonProps {
  partnerLogo?: { url: string; alt?: string };
}

export const Header: FC<HeaderProps> = ({
  className,
  partnerLogo,
  ...props
}) => {
  const isRxPage = location.pathname.startsWith("/balise-rx");

  if (isRxPage)
    return (
      <div className={styles.BaliseRxHeader}>
        <Logo
          dark={false}
          url={REACT_APP_SITEWEB_VOB2C_URL ?? ""}
          temp={false}
        />
      </div>
    );

  return (
    <header
      id="rootHeader"
      className={classNames(styles.header, className)}
      {...props}
    >
      <div onClick={trackClickHandler("RetourAccueil")}>
        <Logo
          temp={false}
          dark={false}
          url={DEFAULT_CONFIG.header.publicWebsiteURL}
        />
      </div>
      {partnerLogo?.url && (
        <img src={partnerLogo.url} alt={partnerLogo.alt ?? "Logo partenaire"} />
      )}
    </header>
  );
};
