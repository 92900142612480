export const UNIVERSIGN_SCRIPT_ID = 'universign';
export const UNIVERSIGN_EVENT = 'pdsEvent';

export enum RedirectionMode {
  IN = 'IN',
  OUT = 'OUT',
}

export enum UniversignEventType {
  BEGIN = 'begin',
  END = 'end',
}

export enum UniversignStatus {
  CANCELED = 'canceled',
  SIGNED = 'signed',
  FAILED = 'failed',
  READY = 'ready',
  PENDING_VALIDATION = 'pending-validation',
  CODE_SENT = 'code-sent',
}

export interface UniversignEvent extends Event {
  type: string;
  detail: {
    eventType: UniversignEventType;
    signerId: string;
    status: UniversignStatus;
  };
}

export interface UniversignConfig {
  redirectionMode?: RedirectionMode;
}

declare global {
  interface Window {
    universignSigInit?(
      domId: string,
      signerId: string,
      config: UniversignConfig,
      targetUrl?: string
    ): void;
  }
}
