import { ForwardedRef, forwardRef } from "react";
import { InputText, InputProps } from "@roole/components-library";

const InputIBANRender = (
  props: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const reformatIBANInputField = (
    eventTarget: EventTarget & HTMLInputElement
  ) => {
    const format = (value: string) => {
      return value
        .replace(/[^\dA-Z]/gi, "")
        .toUpperCase()
        .replace(/(.{4})/g, "$1 ")
        .trim();
    };
    const countSpaces = (text: string) => {
      const spaces = text.match(/(\s+)/g);
      return spaces ? spaces.length : 0;
    };

    const position = eventTarget.selectionEnd;
    const previousValue = eventTarget.value;
    eventTarget.value = format(eventTarget.value);

    if (position !== null && position !== eventTarget.value.length) {
      const beforeCaret = previousValue.substring(0, position);
      const countPrevious = countSpaces(beforeCaret);
      const countCurrent = countSpaces(format(beforeCaret));
      eventTarget.selectionEnd = position + (countCurrent - countPrevious);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // add spaces, uppercase and fix cursor position
    const target = e?.target;
    reformatIBANInputField(target);
    // call the onChange function of the InputText component
    props.onChange?.(e);
  };
  return (
    <InputText
      ref={ref}
      maxLength={40} // 34 + 6 spaces
      {...props}
      onChange={handleChange}
    />
  );
}

export const InputIBAN = forwardRef(InputIBANRender);
