import { Block } from '@roole/components-library';
import { Children, ComponentProps, FC, ReactNode } from 'react';

import { classNames, mapSummaryFallback } from '../../helpers';
import styles from './SummaryBlock.module.scss';

interface SummaryBlockProps extends ComponentProps<typeof Block> {
  heading?: ReactNode;
  pending?: boolean;
}

export const SummaryBlock: FC<SummaryBlockProps> = ({
  className,
  heading,
  variant,
  children,
  pending = false,
  ...props
}) => {
  const summary = pending
    ? Children.map(children, mapSummaryFallback)
    : children;

  return (
    <Block
      className={classNames(styles['summary-block'], className)}
      variant={variant}
      {...props}
    >
      {heading && (
        <div className={styles['summary-block__heading']}>{heading}</div>
      )}
      {summary}
    </Block>
  );
};
