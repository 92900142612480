import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { createWizardRoute } from "@roole/components-library";

import { AppRoute } from "./AppRoute";
import { AuthContextProvider, SubscriptionContextProvider } from "./contexts";
import { AuthGuard, Footer, Header } from "./components";
import { SubscriptionWizard } from "./pages";
import BaliseRx from "./pages/BaliseRx/BaliseRx";

export const App = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.toString();

  return (
    <AuthContextProvider>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          partnerLogo={{
            url: "/assets/stellantis.png",
            alt: "Logo Stellantis",
          }}
        />
        <Routes>
          <Route
            index
            element={
              <Navigate
                to={{ pathname: AppRoute.BALISE_RX, search }}
                replace={true}
              />
            }
          />
          {createWizardRoute(
            AppRoute.SUBSCRIPTION,
            <AuthGuard>
              <SubscriptionContextProvider>
                <SubscriptionWizard />
              </SubscriptionContextProvider>
            </AuthGuard>
          )}

          <Route path={`${AppRoute.BALISE_RX}/:id?`} element={<BaliseRx />} />

          <Route
            path="*"
            element={<Navigate to={{ pathname: "/", search }} replace={true} />}
          />
        </Routes>
        <Footer />
      </div>
    </AuthContextProvider>
  );
};
