import styled from "styled-components";
import { mediaQueries, spaces } from "../../../constants/theme/variables";

export const StyledWizzardNav = styled.nav<{ sticked?: boolean }>(
  ({ sticked }) => {
    const position = sticked ? "sticky" : undefined;

    return {
      // relative screenPosition
      position,
      bottom: spaces.l,
      marginBottom: spaces.l,
      paddingLeft: spaces.xs,
      paddingRight: spaces.xs,

      // Flexbox & gap
      display: "flex",
      flexDirection: "column",
      gap: spaces.xs,

      // desktop mediQuery styles
      [mediaQueries.tabletAndDesktop]: {
        flexDirection: "row",
        justifyContent: "center",
        gap: spaces.s,
        bottom: spaces.m,
        marginBottom: spaces.m,
        paddingLeft: spaces.s,
        paddingRight: spaces.s,
        gridColumn: "2 / 1",
      },

      // buttons styles
      [`& > button`]: {
        width: "100%",
        [mediaQueries.tabletAndDesktop]: {
          width: "50%",
        },
        "& > *": {
          pointerEvents: "none",
        },
      },
    };
  }
);
