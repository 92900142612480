import { FC, ReactNode } from 'react';
import { Text } from '@roole/components-library';

import { classNames, CommonProps } from '../../helpers';
import styles from './AccordionsBlock.module.scss';

interface PanelHeadingProps extends CommonProps {
  title: ReactNode;
  subtitle?: ReactNode;
}

export const PanelHeading: FC<PanelHeadingProps> = ({
  className,
  title,
  subtitle,
  ...props
}) => {
  return (
    <div
      className={classNames(
        styles['accordions-block__panel-heading'],
        className
      )}
      {...props}
    >
      <Text textVariant="ui-1" as="p">
        <strong>{title}</strong>
      </Text>
      {subtitle && (
        <Text
          className={styles['accordions-block__panel-heading-subtitle']}
          textVariant="ui-1"
          as="p"
        >
          {subtitle}
        </Text>
      )}
    </div>
  );
};
