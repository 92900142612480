import { createContext, FC, useCallback, useReducer } from 'react';

import { SubscriptionActionType } from './SubscriptionActions';
import {
  initialSubscriptionState,
  subscriptionReducer,
} from './subscriptionReducer';
import { BankDetails, Subscription } from '../../models';
import { DEFAULT_CONFIG, SubscriptionService } from '../../services';
import { useAuth } from '../../hooks';
import { UniversignStatus } from '../../hooks/useESignature/universign-types';

interface SubscriptionContextProps {
  supportPhoneNumber?: string;
  subscription: Partial<Subscription>;
  loading: boolean;
  updating: boolean;
  downloading: boolean;
  updated: boolean;
  eSignatureStatus: UniversignStatus;
  error: string | null;
  getStepDetails(stepIndex: number): Promise<Partial<Subscription> | unknown>;
  updateBankDetails(details: BankDetails): Promise<BankDetails | unknown>;
  eSignatureCompleted(status: UniversignStatus): void;
  downloadFile(url: string): Promise<Blob | unknown>;
}

export const initialSubscriptionContextProps: SubscriptionContextProps = {
  ...initialSubscriptionState,
  getStepDetails: () => Promise.resolve(),
  updateBankDetails: () => Promise.resolve(),
  eSignatureCompleted: (status: UniversignStatus) => status,
  downloadFile: () => Promise.resolve(),
};

export const SubscriptionContext = createContext<SubscriptionContextProps>({
  ...initialSubscriptionContextProps,
});

export const SubscriptionContextProvider: FC = ({ children }) => {
  const { subscription } = useAuth();
  const [state, dispatch] = useReducer(subscriptionReducer, {
    ...initialSubscriptionState,
    subscription: {
      ...initialSubscriptionState.subscription,
      ...subscription,
    },
  });
  const service = SubscriptionService.getInstance();

  const getStepDetailsHandler = useCallback(
    async (stepDetailsIndex: number) => {
      if (service.hasStepDetails(stepDetailsIndex, state.subscription)) {
        return state.subscription;
      }

      dispatch({ type: SubscriptionActionType.GET_STEP_DETAILS });

      try {
        const details = await service.getStepDetails(stepDetailsIndex);
        dispatch({
          type: SubscriptionActionType.GET_STEP_DETAILS_SUCCEEDED,
          details,
        });
        return details;
      } catch (err) {
        dispatch({
          type: SubscriptionActionType.GET_STEP_DETAILS_FAILED,
          error: err as string,
        });
        return err;
      }
    },
    [state.subscription]
  );

  const updateBankDetailsHandler = useCallback(
    async (bankDetails: BankDetails) => {
      dispatch({ type: SubscriptionActionType.UPDATE_BANK_DETAILS });

      try {
        const details = await service.updateBankDetails(bankDetails);
        dispatch({
          type: SubscriptionActionType.UPDATE_BANK_DETAILS_SUCCEEDED,
          details,
        });
        return details;
      } catch (err) {
        const error = (err as Error).toString();
        dispatch({
          type: SubscriptionActionType.UPDATE_BANK_DETAILS_FAILED,
          error,
        });
        return Promise.reject(error);
      }
    },
    []
  );

  const eSignatureCompletedHandler = useCallback((status: UniversignStatus) => {
    dispatch({ type: SubscriptionActionType.E_SIGNATURE_COMPLETED, status });
  }, []);

  const downloadFileHandler = useCallback(async (url: string) => {
    dispatch({ type: SubscriptionActionType.DOWNLOAD_FILE });

    try {
      const file = await service.downloadFile(url);
      dispatch({ type: SubscriptionActionType.DOWNLOAD_FILE_SUCCEEDED });
      return file;
    } catch (err) {
      dispatch({
        type: SubscriptionActionType.DOWNLOAD_FILE_FAILED,
        error: err as string,
      });
      return err;
    }
  }, []);

  return (
    <SubscriptionContext.Provider
      value={{
        ...state,
        supportPhoneNumber: DEFAULT_CONFIG.support.phoneNumber,
        getStepDetails: getStepDetailsHandler,
        updateBankDetails: updateBankDetailsHandler,
        eSignatureCompleted: eSignatureCompletedHandler,
        downloadFile: downloadFileHandler,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
