import { useEffect } from 'react';
import {
  NEXT_BTN,
  useWizard,
  WizardNav,
  WizardStep,
} from '@roole/components-library';

import { BlockGroup, HtmlText } from '../../components';
import { SubscriptionStep } from '../SubscriptionWizard/SubscriptionStep';
import { garantiesFallback, hotDealsFallback } from '../../helpers';
import { useSubscription } from '../../hooks';

interface WelcomeStepProps {
  disabled?: boolean;
}

export const WelcomeStep: WizardStep<WelcomeStepProps> = ({ disabled }) => {
  const {
    loading,
    supportPhoneNumber,
    subscription: { contract },
  } = useSubscription();
  const { initWizard } = useWizard();
  const garanties = contract?.garanties || garantiesFallback;
  const hotDeals = contract?.hotDeals || hotDealsFallback;
  const pending = loading || disabled;

  useEffect(() => {
    initWizard();
  }, [initWizard]);

  return (
    <SubscriptionStep
      title="Bienvenue"
      subtitle={
        'Finalisez votre adhésion Roole, en quelques clics, pour compléter votre assurance auto principale et protéger votre véhicule.'
      }
      supportPhoneNumber={supportPhoneNumber}
      aside={<BlockGroup blocks={hotDeals} variant="secondary" />}
    >
      <WizardNav
        prevStepLabel={
          <HtmlText variant="subheading" text={contract?.packLabel} />
        }
        nextStepLabel="Finaliser mon adhésion"
        nextCustomAttributes={{
          id: 'SuivantHeader',
        }}
        disabled={pending}
      />
      <BlockGroup blocks={garanties} />
      <WizardNav
        buttons={NEXT_BTN}
        nextStepLabel="Finaliser mon adhésion"
        nextCustomAttributes={{
          id: 'SuivantFooter',
        }}
        disabled={pending}
      />
    </SubscriptionStep>
  );
};
