import { UniversignStatus } from './universign-types';
import { Action, ActionWithProps } from '../../helpers';

export enum ESignatureActionType {
  LOAD_SCRIPT = 'LOAD_SCRIPT',
  LOAD_SCRIPT_SUCCEEDED = 'LOAD_SCRIPT_SUCCEEDED',
  EMBED_IFRAME = 'EMBED_IFRAME',
  EMBED_IFRAME_SUCCEEDED = 'EMBED_IFRAME_SUCCEEDED',
  E_SIGNATURE = 'E_SIGNATURE',
  E_SIGNATURE_COMPLETED = 'E_SIGNATURE_COMPLETED',
  E_SIGNATURE_FAILED = 'E_SIGNATURE_FAILED',
}

export type ESignatureAction =
  | Action<ESignatureActionType.LOAD_SCRIPT>
  | Action<ESignatureActionType.LOAD_SCRIPT_SUCCEEDED>
  | Action<ESignatureActionType.EMBED_IFRAME>
  | Action<ESignatureActionType.EMBED_IFRAME_SUCCEEDED>
  | Action<ESignatureActionType.E_SIGNATURE>
  | ActionWithProps<
      ESignatureActionType.E_SIGNATURE_COMPLETED,
      { status: UniversignStatus }
    >
  | ActionWithProps<ESignatureActionType.E_SIGNATURE_FAILED, { error: string }>;
