import { Button } from "@roole/components-library";
import { classNames } from "../../../helpers";
import { useWizzard } from "../Wizzard.contexte";
import { StyledWizzardNav } from "./WizzardNav.styled";

const WizzardNav: React.FC = () => {
  const {
    step,
    prevStep,
    nextStep,
    navBtnsConfig: { prevBtn, nextBtn },
    stepsConfig,
  } = useWizzard();

  return (
    <StyledWizzardNav sticked={stepsConfig[step]?.stickedNav}>
      {!prevBtn?.hidden && (
        <Button
          variant="outlined-light"
          customAttributes={{
            id: "Retour",
            className: classNames("center-label"),
            type: "button",
            disabled: prevBtn?.disabled,
          }}
          size="small"
          onClick={prevBtn?.handler || prevStep}
        >
          {prevBtn?.label ? prevBtn?.label : "Précédent"}
        </Button>
      )}
      {!nextBtn?.hidden && (
        <Button
          variant="primary"
          customAttributes={{
            id: "Suivant",
            className: classNames("center-label", "isNext"),
            type: "button",
            disabled: nextBtn?.disabled,
          }}
          size="small"
          onClick={nextBtn?.handler || nextStep}
        >
          {nextBtn?.label ? nextBtn?.label : "Suivant"}
        </Button>
      )}
    </StyledWizzardNav>
  );
};

export default WizzardNav;
