import { Skeleton } from '@roole/components-library';

import { BusinessDetailsGroup } from '../models';
import { BlockField } from '../components';

export const generateFallbacks = <T,>(
  numItems: number,
  generator: (index: number) => T
) => Array.from(Array(numItems)).map((_, index) => generator(index));

export const mapSummaryFallback = () => (
  <BlockField label={<Skeleton width="80%" />}>
    <Skeleton width="60%" />
  </BlockField>
);

export const garantiesFallback = generateFallbacks<BusinessDetailsGroup>(
  3,
  () =>
    ({
      title: <Skeleton />,
      items: generateFallbacks(3, (index: number) => ({
        id: `item-${index}`,
        title: <Skeleton width="80%" />,
        subtitle: <Skeleton width="60%" />,
      })),
    } as unknown as BusinessDetailsGroup)
);

export const hotDealsFallback = generateFallbacks<BusinessDetailsGroup>(
  2,
  () =>
    ({
      title: <Skeleton dark />,
      items: generateFallbacks(2, (index: number) => ({
        id: `item-${index}`,
        title: <Skeleton width="80%" dark />,
        subtitle: <Skeleton width="60%" dark />,
      })),
    } as unknown as BusinessDetailsGroup)
);
