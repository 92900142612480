import { FC } from "react";
import { Text } from "@roole/components-library";

import { HtmlText } from "../HtmlText/HtmlText";
import { classNames, CommonProps } from "../../helpers";
import { DEFAULT_CONFIG } from "../../services";
import styles from "./Footer.module.scss";

export const Footer: FC<CommonProps> = ({ className, ...props }) => {
  const isRxPage = location.pathname.startsWith("/balise-rx");

  return (
    <footer
      className={classNames(
        styles.footer,
        isRxPage && styles.rxFooter,
        className
      )}
      {...props}
    >
      <HtmlText
        variant="ui-2"
        text={`Roole est une marque d'IDENTICAR SAS, société de courtage en assurance, immatriculée à l'ORIAS sous le numéro N° 09.052.611 : 
        registre des intermédiaires d'assurance accessible sur <a href="${DEFAULT_CONFIG.footer.oriasURL}" rel="noreferrer" target="_blank">${DEFAULT_CONFIG.footer.oriasURL}</a>`}
      />

      <ul className={styles.footer__links}>
        {isRxPage && (
          <>
            <li>
              <a
                id="Aide&Contact"
                href={DEFAULT_CONFIG.footer.baliseRx.aideEtContact}
                rel="noreferrer"
                target="_blank"
              >
                <Text textVariant="ui-2">Aide & Contact</Text>
              </a>
            </li>
            <li>
              <a
                id="ConditionsGeneralesVente"
                href={
                  DEFAULT_CONFIG.footer.baliseRx.conditionsGeneralesVenteURL
                }
                rel="noreferrer"
                target="_blank"
              >
                <Text textVariant="ui-2">Conditions générales de vente</Text>
              </a>
            </li>
            <li>
              <a
                id="DroitRetractation"
                href={DEFAULT_CONFIG.footer.baliseRx.droitRetractation}
                rel="noreferrer"
                target="_blank"
              >
                <Text textVariant="ui-2">Droit de rétractation</Text>
              </a>
            </li>
          </>
        )}
        <li>
          <a
            id="PolitiqueConfidentialite"
            href={DEFAULT_CONFIG.footer.privacyPolicyURL}
            rel="noreferrer"
            target="_blank"
          >
            <Text textVariant="ui-2">Politique de confidentialité</Text>
          </a>
        </li>
        <li>
          <a
            id="MentionsLegales"
            href={DEFAULT_CONFIG.footer.legalNoticesURL}
            rel="noreferrer"
            target="_blank"
          >
            <Text textVariant="ui-2">Mentions légales</Text>
          </a>
        </li>
        <li>
          <a
            id="CharteCookies"
            href={DEFAULT_CONFIG.footer.cookiesURL}
            rel="noreferrer"
            target="_blank"
          >
            <Text textVariant="ui-2">Charte des cookies</Text>
          </a>
        </li>
      </ul>
    </footer>
  );
};
