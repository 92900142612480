type FormatCurrencyOptions = Intl.NumberFormatOptions & {
  locales?: string | string[];
};

export const formatCurrency = (
  num: number,
  { locales = 'fr-FR', ...options }: FormatCurrencyOptions = {}
) =>
  new Intl.NumberFormat(locales, {
    currency: 'EUR',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    ...options,
    style: 'currency',
  }).format(num);
