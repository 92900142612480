import { ApiService } from "./ApiService";
import { API_CONFIG } from "./ApiConfig";
import { BankDetails, Subscription, SubscriptionKeys } from "../models";
import { REACT_APP_ADHESION_API_URL } from "../constants/env";

export class SubscriptionService {
  private static instance: SubscriptionService;
  private readonly api: ApiService;
  private readonly stepResources: SubscriptionKeys[][];

  private constructor() {
    this.api = new ApiService(REACT_APP_ADHESION_API_URL);
    this.stepResources = this.getStepResources();
  }

  public static getInstance(): SubscriptionService {
    if (!this.instance) {
      this.instance = new SubscriptionService();
    }

    return this.instance;
  }

  getStepDetails(stepIndex = -1): Promise<Partial<Subscription>> {
    const config = API_CONFIG[stepIndex] ?? API_CONFIG[0];

    return this.api
      .get<Partial<Subscription>>(config.endpoint, {
        withCredentials: true,
      })
      .then((data) =>
        typeof config.transformResponse === "function"
          ? config.transformResponse(data)
          : data
      );
  }

  updateBankDetails(bankDetails: BankDetails): Promise<BankDetails> {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { directDebit, ...props } = bankDetails;

    return this.api
      .post<BankDetails>("/VosInformations", props as BankDetails, {
        withCredentials: true,
      })
      .then(() => ({ ...bankDetails }));
  }

  downloadFile(url: string): Promise<Blob> {
    return this.api.get<Blob>(url, { responseType: "blob" });
  }

  getStepResources(): SubscriptionKeys[][] {
    return [
      ["contract"],
      ["votreVehicule", "vosInformationsPersonnelles"],
      [],
      ["eSignatureOptions"],
      [],
    ];
  }

  hasStepDetails(
    stepIndex: number,
    subscription: Partial<Subscription>
  ): boolean {
    return this.stepResources[stepIndex]
      ? this.stepResources[stepIndex].every((key) => !!subscription[key])
      : false;
  }
}
