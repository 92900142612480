/* eslint-disable  @typescript-eslint/no-explicit-any */
const mergeStyles = (_src: Record<string, any>, toAdd: any) => {
  if (typeof _src !== "object") return toAdd;
  const src = { ..._src };

  if (typeof toAdd === "object")
    for (const key in toAdd) src[key] = mergeStyles(src[key], toAdd[key]);

  return src;
};

export default mergeStyles;
