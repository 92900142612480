import useMediaQuery from "../../../hooks/useMediaQuery";
import { useWizzard } from "../Wizzard.contexte";
import {
  DesktopHeader,
  HeaderTitles,
  MobileAndTabletStepper,
  StickedHeader,
} from "./WizzardHeader.styled";
import { StyledWizzardHeaderStepProps } from "./WizzardHeader.types";

const StyledWizzardHeaderStep: React.FC<StyledWizzardHeaderStepProps> = ({
  title,
  subtitle,
  subtitleTxt,
  isStep,
}) => {
  const isMobileAndTablet = useMediaQuery("mobileAndTablet");
  const { dark, step, countSteps, stepsConfig } = useWizzard();

  subtitleTxt;
  return (
    <>
      {isStep && (
        <StickedHeader sticked={!!stepsConfig[step]?.stickedHeader}>
          {isMobileAndTablet ? (
            <MobileAndTabletStepper
              step={step}
              countSteps={countSteps}
              label={stepsConfig.find((v) => v.step === step)?.label ?? "Error"}
            />
          ) : (
            <DesktopHeader />
          )}
        </StickedHeader>
      )}

      <HeaderTitles
        dark={dark}
        isMobileAndTablet={isMobileAndTablet}
        title={title}
        subtitle={subtitle}
        isStep={isStep}
      />
    </>
  );
};

export default StyledWizzardHeaderStep;
