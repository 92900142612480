import styled from "styled-components";
import { defaultLayout } from "../../../constants/theme/global";
import mergeStyles from "../../../helpers/mergeStyles";

const styles = mergeStyles(defaultLayout, {});

export const StyledWizzardAsideContent = styled.aside(() => {
  return styles;
});
const StyledWizzardContent = styled.aside(() => {
  return styles;
});

export default StyledWizzardContent;
