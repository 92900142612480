import { Text } from "@roole/components-library";
import { ExternalLink } from "../../../../../../components/ExternalLink/ExternalLink";
import { classNames } from "../../../../../../helpers";
import styles from "./CarLocalization.module.scss";

export const CarLocalization: React.FC = () => {
  return (
    <div className={styles.CarLocalization}>
      <Text
        textVariant="display-1"
        as="h1"
        className={classNames(
          styles.CarLocalization__header,
          styles.CarLocalization__title1
        )}
      >
        Un dispositif unique
      </Text>
      <Text
        textVariant="display-1"
        as="h1"
        className={classNames(
          styles.CarLocalization__header,
          styles.CarLocalization__title2
        )}
      >
        pour retrouver votre véhicule
      </Text>

      <div className={styles.findCar}>
        <div className={styles.findCar__infosBlock}>
          <Text
            textVariant="heading-2"
            as="h2"
            className={classNames()}
            style={{ color: "white" }}
          >
            Identification facilitée
          </Text>
          <Text
            textVariant="body"
            as="p"
            className={classNames()}
            style={{ color: "white" }}
          >
            Les forces de l’ordre sont notifiées automatiquement sur leur
            smartphone lorsqu’un véhicule déclaré volé est à proximité (jusqu’à
            50 mètres)
          </Text>
        </div>

        <div className={styles.findCar__infosBlock}>
          <Text
            textVariant="heading-2"
            as="h2"
            className={classNames()}
            style={{ color: "white" }}
          >
            Géolocalisation communautaire
          </Text>
          <Text
            textVariant="body"
            as="p"
            className={classNames()}
            style={{ color: "white" }}
          >
            La recherche d’un véhicule est décuplée grâce aux membres équipés de
            l’application Roole Premium qui transmettent la position de la
            balise aux autorités en cas de véhicule volé détecté
          </Text>
        </div>

        <img src="/assets/rx-signal.png" />
      </div>

      <div className={styles.dataSecurity}>
        <div className={styles.dataSecurity__infosBlock}>
          <Text textVariant="heading-2" as="h2" style={{ color: "white" }}>
            <span className={styles["dataSecurity__infosBlock--marked"]}>
              La sécurisation de vos données
            </span>
            <br />
            au coeur du dispositif
          </Text>

          <Text textVariant="body" as="p" style={{ color: "white" }}>
            Les données de localisation sont sécurisées et anonymisées à l’aide
            d’une technologie blockchain chiffrée de bout en bout. Ce dispositif
            aide ainsi les forces de l’ordre à retrouver un véhicule volé.
          </Text>
        </div>

        <img src="/assets/blockchain.png" />
      </div>

      <div className={styles.downloadTheApp}>
        <div className={styles.downloadTheApp__infos}>
          <Text textVariant="heading-6" as="h6" className={classNames()}>
            Connectez votre balise Rx
            <br /> et profitez de tous vos
            <br /> services connectés
          </Text>

          <div style={{ display: "flex", alignItems: "flex-start", gap: 20 }}>
            <div style={{ width: 150, borderRadius: 12 }}>
              <ExternalLink href="https://apps.apple.com/fr/app/roole-connect/id1605740783">
                <img
                  id="navsidebar-app-ios"
                  src="/assets/button-app-app-store-i-os.png"
                  alt="Télécharger dans l'AppStore"
                />
              </ExternalLink>
            </div>
            <div
              style={{
                width: 150,
                borderRadius: 12,
              }}
            >
              <ExternalLink href="https://play.google.com/store/apps/details?id=com.roole.rooleconnect&hl=fr">
                <img
                  id="navsidebar-app-android"
                  src="/assets/button-app-google-play-android.png"
                  alt="Télécharger sur Google Play"
                />
              </ExternalLink>
            </div>
          </div>
        </div>

        {/* <img
          src="/assets/phone-app-open.png"
          className={styles.downloadTheApp__centerImage}
        /> */}
      </div>
    </div>
  );
};
