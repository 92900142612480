import {
  WizzardStep,
  WizzardContent,
  WizzardStepWrapperProps,
  useWizzard,
} from "../../../../components/Wizzard";
import { Block, DropDown, InputText, Text } from "@roole/components-library";
import { RxProduct } from "../../RxProduct/RxProduct";
import styles from "./Informations.module.scss";
import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import {
  ALL_PHONE_NUMBER,
  EMAIL_FORMAT,
  IMMATRICULATION,
} from "../../../../helpers/regex";
import { AddressService } from "../../../../services/AddressService";
import { classNames } from "../../../../helpers";
import { Address } from "../../../../models/Address";
import { RxService } from "../../../../services/RxService";
import { ICommand } from "../../../../models/Command";

const addressService = AddressService.getInstance();
const rxService = RxService.getInstance();

export const Informations: React.FC<WizzardStepWrapperProps> = () => {
  return (
    <WizzardStep
      aside={
        <WizzardContent aside>
          <RxProduct headband={false} />
        </WizzardContent>
      }
    >
      <InformationsContent />
    </WizzardStep>
  );
};

export const InformationsContent: React.FC = () => {
  const [address, _setAddress] = useState<Address | undefined>(undefined);
  const [addressProposals, setAddressProposals] = useState<Address[]>([]);
  const { setNavBtnsConfig, nextStep } = useWizzard();
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    control,
  } = useForm<ICommand>({
    defaultValues: {
      civilite: 0,
      nom: "",
      prenom: "",
      telephoneMobile: "",
      email: "",
      adresse: {
        rue: "",
        ville: "",
        codePostal: "",
        complement: "",
      },
      vehicule: {
        immatriculation: "",
      },
    },
  });
  const {
    civilite,
    adresse: { rue },
  } = watch();

  const onSubmit = async (data: ICommand) => {
    const commande = {
      civilite: data.civilite,
      nom: data.nom.toUpperCase(),
      prenom: data.prenom.toUpperCase(),
      telephoneMobile: data.telephoneMobile,
      email: data.email.toUpperCase(),
      adresse: {
        rue: data.adresse.rue.toUpperCase(),
        complement: data.adresse.complement.toUpperCase(),
        ville: data.adresse.ville.toUpperCase(),
        codePostal: data.adresse.codePostal,
      },
      vehicule: {
        immatriculation: data.vehicule.immatriculation.toUpperCase(),
      },
    };

    const commandId = await rxService.makeCommande(commande);
    localStorage.setItem("commandeRxId", commandId);
    nextStep();
  };
  const setAddress = (address?: Partial<Address>) => {
    _setAddress(address as Address);

    setValue("adresse.rue", address?.rue ?? "");
    setValue("adresse.codePostal", address?.codePostal ?? "");
    setValue("adresse.ville", address?.ville ?? "");
  };

  useEffect(() => {
    setNavBtnsConfig({
      nextBtn: {
        handler: () => {
          handleSubmit(onSubmit)();
        },
      },
    });

    const commandeRxId = localStorage.getItem("commandeRxId");

    if (commandeRxId) {
      rxService.getCommande(commandeRxId).then((data) => {
        reset({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          civilite: (data.civilite as any) === "Monsieur" ? 0 : 1,
          nom: data.nom,
          prenom: data.prenom,
          telephoneMobile: data.telephoneMobile,
          email: data.email,
          adresse: {
            rue: data.adresse?.rue,
            complement: data.adresse?.complement,
            codePostal: data.adresse?.codePostal,
            ville: data.adresse?.ville,
          },
          vehicule: {
            immatriculation: data.vehicule?.immatriculation,
          },
        });

        _setAddress({
          codePostal: data.adresse?.codePostal,
          ville: data.adresse?.ville,
        });
      });
    }

    const addressInputElement = document.querySelector('input[name="rue"]');
    const addressProposalsElement = document.getElementsByClassName(
      styles.addressProposals
    );

    const onFocus = () => {
      addressProposalsElement?.[0]?.classList?.remove(
        styles["addressProposals--hidden"]
      );
    };
    const onUnfocus = () => {
      setTimeout(
        () =>
          addressProposalsElement?.[0]?.classList?.add(
            styles["addressProposals--hidden"]
          ),
        200
      );
    };
    addressInputElement?.addEventListener("focus", onFocus);
    addressInputElement?.addEventListener("blur", onUnfocus);

    return () => {
      addressInputElement?.removeEventListener("focus", onFocus);
      addressInputElement?.removeEventListener("blur", onUnfocus);
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <WizzardContent>
        <RxProduct headband />

        <Block>
          <Text textVariant="heading-4">
            Vos informations
            <br />
            personnelles
          </Text>

          <div className={styles.FormRow}>
            <DropDown
              selectedValue={
                civilite === 0
                  ? {
                      id: 0,
                      name: "M.",
                      disabled: false,
                    }
                  : {
                      id: 1,
                      name: "Mme.",
                      disabled: false,
                    }
              }
              possibleValues={[
                {
                  id: 0,
                  name: "M.",
                  disabled: false,
                },
                {
                  id: 1,
                  name: "Mme.",
                  disabled: false,
                },
              ]}
              setSelectedValue={(v) => {
                setValue("civilite", v.id as 0 | 1);
              }}
            />
            <div className={styles.empty} />
          </div>
          <div className={styles.FormRow}>
            <InputText
              {...register("nom", { required: true })}
              label="Nom"
              error={errors.nom ? "Entrez un nom valide" : undefined}
            />
            <InputText
              {...register("prenom", { required: true })}
              label="Prénom"
              error={errors.prenom ? "Entrez un prenom valide" : undefined}
            />
          </div>
          <div className={styles.FormRow}>
            <InputText
              {...register("email", {
                required: true,
                validate: (v) => EMAIL_FORMAT.test(v),
              })}
              label="Email"
              placeholder="exemple@roole.fr"
              error={
                errors.email
                  ? "Entrez un email valide: exemple@roole.fr"
                  : undefined
              }
            />

            <Controller
              name="telephoneMobile"
              control={control}
              rules={{
                required: true,
                validate: (v) => ALL_PHONE_NUMBER.test(v.replaceAll(" ", "")),
              }}
              render={({ field }) => {
                console.log("FIELD", field);

                return (
                  <InputText
                    placeholder="06 07 08 09 10"
                    mask="99 99 99 99 99"
                    maskChar=""
                    label="Téléphone"
                    error={
                      errors.telephoneMobile
                        ? "Entrez un numéro de téléphone valide"
                        : undefined
                    }
                    {...field}
                  />
                );
              }}
            />
          </div>

          <InputText
            {...register("adresse.rue", {
              required: true,
              validate: () => !!address,
              onChange: (e) => {
                const addressProposalsElement = document.getElementsByClassName(
                  styles.addressProposals
                );
                addressProposalsElement?.[0]?.classList?.remove(
                  styles["addressProposals--hidden"]
                );

                _setAddress(undefined);

                const rue = e.currentTarget.value;
                addressService.searchAddress(rue).then((v) => {
                  setAddressProposals(v);
                });
              },
              value: rue,
            })}
            label="Adresse"
            error={
              errors.adresse?.rue
                ? "Vous devez séléctionner une des propositions"
                : undefined
            }
          />

          {addressProposals.length > 0 && (
            <div className={classNames(styles.addressProposals)}>
              {addressProposals.map((address) => (
                <p
                  key={address.rue}
                  onClick={() => {
                    setAddress(address);
                    setAddressProposals([]);
                  }}
                >
                  {address.inputOutput}
                </p>
              ))}
            </div>
          )}

          {!!address && (
            <div className={styles.FormRow}>
              <InputText value={address?.ville} disabled={true} label="Ville" />
              <InputText
                value={address?.codePostal}
                disabled={true}
                label="Code Postal"
              />
            </div>
          )}

          <InputText
            {...register("adresse.complement", { required: false })}
            label="Complément d'adresse"
          />
        </Block>

        <Block>
          <Text textVariant="heading-4">
            Vos informations
            <br />
            véhicule
          </Text>

          <div className={styles.FormRow}>
            <Controller
              name="vehicule.immatriculation"
              control={control}
              rules={{
                required: true,
                validate: (v) => IMMATRICULATION.test(v.toUpperCase()),
              }}
              render={({ field }) => {
                return (
                  <InputText
                    mask="aa-999-aa"
                    maskChar=""
                    label="Plaque d'immatriculation"
                    placeholder="AB-123-CD"
                    error={
                      errors.vehicule?.immatriculation
                        ? "Entrez une plaque d'immatriculation valide"
                        : undefined
                    }
                    {...field}
                  />
                );
              }}
            />

            <div className={styles.empty} />
          </div>
        </Block>
      </WizzardContent>
    </form>
  );
};
