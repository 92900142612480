import { useEffect, useState, useMemo } from 'react';

export const useMatchMedia = (mediaQuery: string) => {
  const query = useMemo(() => window.matchMedia(mediaQuery), [mediaQuery]);
  const [match, setMatch] = useState(query.matches);

  useEffect(() => {
    const onChange = () => setMatch(query.matches);
    query.addEventListener('change', onChange);
    onChange();

    return () => query.removeEventListener('change', onChange);
  }, [query]);

  return match;
};
