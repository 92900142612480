import { FC } from 'react';

import { classNames, CommonProps } from '../../helpers';
import styles from './QuestionBlock.module.scss';

export const DarkBlock: FC<CommonProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames(className, styles['question-block--dark'])}
      {...props}
    >
      {children}
    </div>
  );
};
