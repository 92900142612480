import { mediaQueries, spaces } from "./variables";

export const defaultLayout = {
  display: "flex",
  flexDirection: "column",
  gap: spaces.m,

  [mediaQueries.desktop]: {
    gap: spaces.l,
  },
};
