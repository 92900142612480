import {
  StyledWizzardStep,
  StyledWizzardContentStep,
  StyledWizzardBodyStep,
} from "./WizzardStep.styled";
import { WizzardStepProps } from "./WizzardStep.types";
import WizzardNav from "../WizzardNav";

const WizzardStep: React.FC<WizzardStepProps> = ({
  children,
  className,
  aside,
}) => {
  return (
    <StyledWizzardStep hasAside={!!aside} className={className}>
      <StyledWizzardBodyStep>
        <StyledWizzardContentStep>{children}</StyledWizzardContentStep>
        {!!aside && aside}

        <WizzardNav />
      </StyledWizzardBodyStep>
    </StyledWizzardStep>
  );
};

export default WizzardStep;
