import { Text } from "@roole/components-library";
import { classNames } from "../../../../../../helpers";
import styles from "./StillProtection.module.scss";

export const StillProtection: React.FC = () => {
  return (
    <div className={styles.StillProtection}>
      <Text
        textVariant="display-1"
        as="h1"
        className={classNames(styles.StillProtection__title1)}
      >
        En France <br />
        un véhicule est volé <br />
      </Text>
      <Text
        textVariant="display-1"
        as="h1"
        className={classNames(styles.StillProtection__title2)}
      >
        toutes les 4 minutes
      </Text>

      <Text
        textVariant="heading-3"
        as="h3"
        className={classNames(styles.StillProtection__subtitle)}
      >
        Pour cette raison, Roole invente la balise Rx
      </Text>

      <div className={styles.StillProtection__rBlock}>
        <div className={styles["StillProtection__rBlock--before"]}>
          <Text
            textVariant="heading-5"
            as="h5"
            className={classNames(
              styles["StillProtection__solutionHeader--marked"],
              styles["StillProtection__solutionHeader--after"]
            )}
          >
            Solution connectée
          </Text>
          <Text
            textVariant="heading-5"
            as="h5"
            className={classNames(
              styles.StillProtection__solutionHeader,
              styles["StillProtection__solutionHeader--after"]
            )}
          >
            de protection
            <br />
            contre le vol
          </Text>
        </div>

        <img src="/assets/r-Roole-shadow.png" />

        <div className={styles["StillProtection__rBlock--after"]}>
          <Text
            textVariant="heading-5"
            as="h5"
            className={classNames(
              styles["StillProtection__solutionHeader--marked"],
              styles["StillProtection__solutionHeader--before"]
            )}
          >
            Ecoconduite
          </Text>
          <Text
            textVariant="heading-5"
            as="h5"
            className={classNames(styles.StillProtection__solutionHeader)}
          >
            score et conseils
          </Text>
        </div>
      </div>
    </div>
  );
};
