import { FC, ReactNode, useCallback, useMemo } from 'react';
import { Accordion, Block } from '@roole/components-library';

import { PanelHeading } from './PanelHeading';
import { HtmlText } from '../HtmlText/HtmlText';
import { ShadowContainer } from '../ShadowContainer/ShadowContainer';
import { classNames, CommonProps, trackClickHandler } from '../../helpers';
import styles from './AccordionsBlock.module.scss';

interface AccordionItem {
  id: string;
  title: ReactNode;
  subtitle?: ReactNode;
  description?: string;
}

interface AccordionsBlockProps extends CommonProps {
  heading?: ReactNode;
  variant?: 'primary' | 'secondary';
  items?: AccordionItem[];
}

export const AccordionsBlock: FC<AccordionsBlockProps> = ({
  className,
  heading,
  variant,
  items,
  ...props
}) => {
  const expands = useMemo(
    () => new Map((items || []).map((item) => [item.id, false])),
    [items]
  );

  const accordionClickHandler = useCallback(
    (id: string) => () => {
      if (!expands.has(id)) {
        return;
      }

      expands.set(id, !expands.get(id));
      expands.get(id) && trackClickHandler(id)();
    },
    [expands]
  );

  return items && items.length > 0 ? (
    <Block
      className={classNames(styles['accordions-block'], className)}
      variant={variant}
      {...props}
    >
      {heading}
      <div>
        {items.map((item, index) => (
          <div
            key={index}
            className={styles['accordions-block__panel']}
            onClick={accordionClickHandler(item.id)}
          >
            {item.description ? (
              <Accordion
                accordionLabel={
                  <PanelHeading title={item.title} subtitle={item.subtitle} />
                }
                accordionContent={
                  <ShadowContainer>
                    <HtmlText
                      className={styles['accordions-block__panel-content']}
                      text={item.description}
                      variant="ui-1"
                    />
                  </ShadowContainer>
                }
                type="expressive"
                useDefinedStyle={false}
              />
            ) : (
              <PanelHeading {...item} />
            )}
          </div>
        ))}
      </div>
    </Block>
  ) : null;
};
