const _breakpoints = {
  maxMobile: 600,
  maxTablet: 1024,
  minSmallDesktop: 1366,
  minBigDesktop: 1920,
};

const breakpoints = {
  maxMobile: _breakpoints.maxMobile + "px",

  minTablet: _breakpoints.maxMobile + 1 + "px",
  maxTablet: _breakpoints.maxTablet + "px",

  minDesktop: _breakpoints.maxTablet + 1 + "px",
  minSmallDesktop: _breakpoints.minSmallDesktop + "px",
  minBigDesktop: _breakpoints.minBigDesktop + "px",
};

export const medias = {
  mobile: `(max-width: ${breakpoints.maxMobile})`,
  tablet: `(min-width: ${breakpoints.minTablet}) and (max-width: ${breakpoints.maxTablet})`,
  mobileAndTablet: `(max-width: ${breakpoints.maxTablet})`,
  tabletAndDesktop: `(min-width: ${breakpoints.minTablet})`,
  desktop: `(min-width: ${breakpoints.minDesktop})`,
  smallDesktop: `(min-width: ${breakpoints.minSmallDesktop})`,
  bigDesktop: `(min-width: ${breakpoints.minBigDesktop})`,
};

export const mediaQueries = {
  mobile: `@media${medias.mobile}`,
  tablet: `@media${medias.tablet}`,
  mobileAndTablet: `@media${medias.mobileAndTablet}`,
  tabletAndDesktop: `@media${medias.tabletAndDesktop}`,
  desktop: `@media${medias.desktop}`,
  smallDesktop: `@media${medias.smallDesktop}`,
  bigDesktop: `@media${medias.bigDesktop}`,
};

export const spacesNb = {
  xs: 8,
  s: 16,
  m: 24,
  l: 32,
  xl: 40,
  xxl: 80,
};

export const spaces = {
  xs: spacesNb.xs + "px",
  s: spacesNb.s + "px",
  m: spacesNb.m + "px",
  l: spacesNb.l + "px",
  xl: spacesNb.xl + "px",
  xxl: spacesNb.xxl + "px",
};
