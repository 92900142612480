import { toPascalCase } from "../helpers";
import { Subscription } from "../models";

interface EndpointConfig {
  endpoint: string;
  transformResponse?(data: unknown): Partial<Subscription>;
}

interface Garantie {
  titre: string;
  sousTitre: string;
  description: string;
}

interface CategorieGarantie {
  titre: string;
  garanties: Garantie[];
}

interface RawContract {
  categorieGaranties: CategorieGarantie[];
}

interface RawESignatureOptions {
  urlSignature: string;
}

const transformGaranties = (garanties: Garantie[]) =>
  (garanties || []).map(
    ({ titre: title, sousTitre: subtitle, description }) => ({
      id: toPascalCase(`Chevron ${title}`),
      title,
      subtitle,
      description,
    })
  );

const transformCategorieGaranties = (categorieGaranties: CategorieGarantie[]) =>
  (categorieGaranties || []).map(({ titre: title, garanties: items }) => ({
    title,
    items: transformGaranties(items),
  }));

export const API_CONFIG: EndpointConfig[] = [
  {
    endpoint: "/Bienvenue",
    transformResponse: (data: RawContract): Partial<Subscription> => {
      return {
        contract: {
          packLabel: "Votre Pack <strong>Silver PSA Retail</strong>",
          garanties: transformCategorieGaranties(data?.categorieGaranties),
          hotDeals: [
            {
              items: [
                {
                  description:
                    "Curabitur id dapibus ipsum. Duis pretium quis augue at rutrum.",
                  subtitle:
                    "Une équipe dédiée pour vous porter assistance sur la route.",
                  title: "Assistance 24h/24",
                  id: "ChevronAssistance24h/24",
                },
                {
                  description:
                    "Nunc laoreet, libero in elementum dapibus, purus tellus ultricies ante, a luctus lacus quam eu augue.",
                  subtitle:
                    "Des avantages exclusifs dans l’univers de la mobilité, des loisirs et du voyage.",
                  title: "Bons Plans",
                  id: "ChevronBonsPlans",
                },
              ],
              title: "Inclus pour tous nos membres",
            },
          ],
        },
      };
    },
  },
  {
    endpoint: "/VosInformations",
  },
  {
    endpoint: "/VosInformations",
  },
  {
    endpoint: "/SignatureElectronique",
    transformResponse: (data: RawESignatureOptions): Partial<Subscription> => {
      return {
        eSignatureOptions: {
          signerId: (data?.urlSignature || "").split("id=").pop() || "",
        },
      };
    },
  },
  {
    endpoint: "/Felicitations",
  },
];
