import { spaces } from "../../constants/theme/variables";

type YoutubeContainerProps = {
  ratio: string;

  bordered?: boolean;
} & React.IframeHTMLAttributes<HTMLIFrameElement>;

export const YoutubeContainer: React.FC<YoutubeContainerProps> = ({
  ratio,
  bordered,
  ...props
}) => {
  const borderedStyle = bordered
    ? {
        paddingRight: 1,
        border: "1px solid white",
        borderRadius: spaces.s,
        overflow: "hidden",
      }
    : {};
  return (
    <div
      style={{
        width: props.width ?? "100%",
        marginLeft: "auto",
        marginRight: "auto",
        aspectRatio: ratio as string,
        ...borderedStyle,
      }}
    >
      <iframe style={{ width: "100%", height: "100%" }} {...props}></iframe>
    </div>
  );
};
