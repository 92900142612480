import { FC, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { classNames, CommonProps } from "../../helpers";

const ShadowChildren: FC<{ root: Element }> = ({ root, children }) => {
  return ReactDOM.createPortal(children, root);
};

// TODO: this is a WIP version, we should improve this before production
export const ShadowContainer: FC<CommonProps> = ({
  className,
  children,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [shadowRoot, setShadowRoot] = useState<ShadowRoot | null>(null);

  useEffect(() => {
    if (!containerRef.current || containerRef.current.shadowRoot) {
      return;
    }

    const root = containerRef.current.attachShadow({ mode: "open" });
    root.innerHTML = `
      <style>
        * {
          font-family: MaisonNeue,Helvetica,Arial,"Lucida Grande",sans-serif;
        }
      </style>
    `;
    setShadowRoot(root);
  }, [containerRef.current]);

  return (
    <div className={classNames(className)} ref={containerRef} {...props}>
      {shadowRoot && (
        <ShadowChildren root={shadowRoot as unknown as Element}>
          {children}
        </ShadowChildren>
      )}
    </div>
  );
};
