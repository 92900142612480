import { useCallback, useReducer } from "react";

import { ESignatureActionType } from "./ESignatureActions";
import { eSignatureReducer, initialESignatureState } from "./eSignatureReducer";
import {
  RedirectionMode,
  UniversignConfig,
  UniversignEvent,
  UniversignEventType,
  UniversignStatus,
  UNIVERSIGN_EVENT,
  UNIVERSIGN_SCRIPT_ID,
} from "./universign-types";
import { defer, Deffered } from "../../helpers";
import { REACT_APP_UNIVERSIGN_URL } from "../../constants/env";

const defaultScriptLoading = defer<void>();

export const useESignature = (scriptLoading?: Deffered<void>) => {
  const [state, dispatch] = useReducer(eSignatureReducer, {
    ...initialESignatureState,
  });

  const loadScript = useCallback(
    async (url: string) => {
      const loading = scriptLoading ?? defaultScriptLoading;

      if (document.getElementById(UNIVERSIGN_SCRIPT_ID) === null) {
        dispatch({ type: ESignatureActionType.LOAD_SCRIPT });

        const onLoadScriptError = () => {
          document.head.removeChild(script);
          loading.reject(
            `RuntimeError: unable to load ${UNIVERSIGN_SCRIPT_ID}`
          );
        };

        const script = document.createElement("script");
        script.id = UNIVERSIGN_SCRIPT_ID;
        script.src = url;
        script.onload = () => {
          if (typeof window.universignSigInit === "function") {
            dispatch({ type: ESignatureActionType.LOAD_SCRIPT_SUCCEEDED });
            loading.resolve();
          } else {
            onLoadScriptError();
          }
        };
        script.onerror = onLoadScriptError;
        document.head.appendChild(script);
      }

      return loading.promise;
    },
    [defaultScriptLoading, scriptLoading]
  );

  const eventListener = useCallback(
    (evt: UniversignEvent) => {
      switch (evt.detail.eventType) {
        case UniversignEventType.BEGIN:
          if (!state.embeded) {
            dispatch({ type: ESignatureActionType.EMBED_IFRAME_SUCCEEDED });
          }
          break;
        case UniversignEventType.END:
          if (
            [UniversignStatus.SIGNED, UniversignStatus.CANCELED].includes(
              evt.detail.status
            )
          ) {
            dispatch({
              type: ESignatureActionType.E_SIGNATURE_COMPLETED,
              status: evt.detail.status,
            });
          }
          if (
            !evt.detail.status ||
            evt.detail.status === UniversignStatus.FAILED
          ) {
            dispatch({
              type: ESignatureActionType.E_SIGNATURE_FAILED,
              error: `RuntimeError: something went wrong durin eSignature`,
            });
          }
          break;
        default:
          dispatch({
            type: ESignatureActionType.E_SIGNATURE_FAILED,
            error: `RuntimeError: something went wrong durin eSignature`,
          });
          break;
      }
    },
    [state.embeded]
  );

  const embedIframe = useCallback(
    (
      domId: string,
      signerId: string,
      config: UniversignConfig = { redirectionMode: RedirectionMode.IN }
    ) => {
      if (typeof window.universignSigInit !== "function") {
        throw new Error(
          `RuntimeError: You should load ${UNIVERSIGN_SCRIPT_ID} script`
        );
      }

      dispatch({ type: ESignatureActionType.EMBED_IFRAME });

      window.addEventListener(UNIVERSIGN_EVENT, eventListener as EventListener);
      window.universignSigInit(
        domId,
        signerId,
        config,
        REACT_APP_UNIVERSIGN_URL
      );
    },
    []
  );

  const setup = useCallback(
    (domId: string, signerId: string, config?: UniversignConfig) => {
      const url = `${REACT_APP_UNIVERSIGN_URL}/sig/embed.js`;

      loadScript(url)
        .then(() => embedIframe(domId, signerId, config))
        .catch((err) => {
          dispatch({
            type: ESignatureActionType.E_SIGNATURE_FAILED,
            error: err,
          });
        });

      return () => {
        window.removeEventListener(
          UNIVERSIGN_EVENT,
          eventListener as EventListener
        );
      };
    },
    [loadScript, embedIframe, eventListener]
  );

  const pending = state.loading || state.embeding || state.signing;

  return {
    ...state,
    pending,
    embedIframe,
    eventListener,
    loadScript,
    setup,
  };
};
