import { AxiosError, AxiosResponse } from "axios";

import { API_CONFIG } from "./ApiConfig";
import { ApiService } from "./ApiService";
import { SubscriptionService } from "./SubscriptionService";
import { Subscription } from "../models";
import { REACT_APP_ADHESION_API_URL } from "../constants/env";

export class AuthService {
  private static instance: AuthService;
  private readonly api: ApiService;

  private constructor() {
    this.api = new ApiService(REACT_APP_ADHESION_API_URL);
    this.api.httpClient.interceptors.response.use(this.mapResponse);
  }

  public static getInstance(): AuthService {
    if (!this.instance) {
      this.instance = new AuthService();
    }

    return this.instance;
  }

  login(token: string | null): Promise<Partial<Subscription>> {
    return SubscriptionService.getInstance()
      .getStepDetails(0)
      .catch((err: AxiosError) =>
        err.response?.status === 403 && token
          ? this.getCookie(token)
          : Promise.reject(`RuntimeError: something went wrong`)
      );
  }

  getCookie(token: string): Promise<Partial<Subscription>> {
    return this.api.get<Partial<Subscription>>("/Authentication/Callback", {
      withCredentials: true,
      params: { token },
    });
  }

  protected mapResponse(
    response: AxiosResponse<Partial<Subscription>>
  ): AxiosResponse<Partial<Subscription>> {
    const endpoint = response.request?.responseURL?.split("/").pop();
    const config = API_CONFIG.find((item) => item.endpoint.endsWith(endpoint));
    const data =
      typeof config?.transformResponse === "function"
        ? config.transformResponse(response.data)
        : response.data;

    return { ...response, data };
  }
}
