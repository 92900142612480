import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { WelcomeStep } from '../../pages';
import { useAuth } from '../../hooks';
import styles from './Auth.module.scss';

export const Auth = ({ ...props }) => {
  const [searchParams] = useSearchParams();
  const token: string | null = searchParams.get('token');
  const { login } = useAuth();

  useEffect(() => {
    login(token ? decodeURIComponent(token) : null);
  }, [token]);

  return (
    <main className={styles.auth} {...props}>
      <WelcomeStep path="" disabled />
    </main>
  );
};
