import { Block, Text } from "@roole/components-library";
import { ExternalLink } from "../../../../../components/ExternalLink/ExternalLink";
import { classNames } from "../../../../../helpers";
import styles from "./DownloadApp.module.scss";

type DownloadAppProps = {
  className?: string;
};

export const DownloadApp: React.FC<DownloadAppProps> = ({ className }) => {
  return (
    <Block className={classNames(styles.aside, className)}>
      <Text textVariant="heading-5" style={{ textAlign: "center" }}>
        Téléchargez l&apos;application Roole Premium
      </Text>

      <div className={styles.stores}>
        <div>
          <ExternalLink href="https://apps.apple.com/fr/app/roole-connect/id1605740783">
            <img
              id="navsidebar-app-ios"
              src="/assets/button-app-app-store-i-os.png"
              alt="Télécharger dans l'AppStore"
            />
          </ExternalLink>
        </div>
        <div>
          <ExternalLink href="https://play.google.com/store/apps/details?id=com.roole.rooleconnect&hl=fr">
            <img
              id="navsidebar-app-android"
              src="/assets/button-app-google-play-android.png"
              alt="Télécharger sur Google Play"
            />
          </ExternalLink>
        </div>
      </div>
    </Block>
  );
};
