import { Children, FC, ReactNode } from 'react';
import { Text } from '@roole/components-library';

import { classNames, CommonProps } from '../../helpers';
import styles from './BlockField.module.scss';

interface BlockFieldProps extends CommonProps {
  label: ReactNode;
  inline?: boolean;
  hint?: string;
  readonly?: boolean;
}

export const BlockField: FC<BlockFieldProps> = ({
  className,
  label,
  inline = false,
  readonly = false,
  hint,
  children,
  ...props
}) => {
  const isReadonly = typeof children === 'string' || readonly;

  return Children.count(children) > 0 ? (
    <div
      className={classNames(
        styles['block-field'],
        !isReadonly && styles['block-field--writable'],
        inline && styles['block-field--inline'],
        className
      )}
      {...props}
    >
      <Text className={styles['block-field__label']} textVariant="ui-1" as="p">
        {label}
      </Text>
      <div className={styles['block-field__group']}>
        {isReadonly ? (
          <Text textVariant="ui-1" as="p">
            {children}
          </Text>
        ) : (
          children
        )}
        {hint && (
          <Text
            className={styles['block-field__hint']}
            textVariant="ui-2"
            as="p"
          >
            {hint}
          </Text>
        )}
      </div>
    </div>
  ) : null;
};
