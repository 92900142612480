type FormatNumberOptions = Intl.NumberFormatOptions & {
  locales?: string | string[];
  unit?: string;
};

export const formatNumber = (
  value: number | bigint,
  { locales = 'fr-FR', unit, ...options }: FormatNumberOptions = {}
): string =>
  `${new Intl.NumberFormat(locales, options).format(value)}`.concat(
    unit ? `\u202F${unit}` : ``
  );
