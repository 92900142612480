type FormatDateOptions = Intl.DateTimeFormatOptions & {
  locales?: string | string[];
  fallback?: string;
};

export const formatDate = (
  date?: string | Date | null,
  { locales = 'fr-FR', fallback = '?', ...options }: FormatDateOptions = {
    dateStyle: 'short',
  }
): string => {
  if (!date) {
    return '';
  }

  try {
    return new Intl.DateTimeFormat(locales, {
      dateStyle: 'medium',
      ...options,
    }).format(new Date(date));
  } catch (err) {
    return fallback;
  }
};
