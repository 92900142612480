import { Text } from "@roole/components-library";
import YoutubeContainer from "../../../../../../components/YoutubeContainer";
import useMediaQuery from "../../../../../../hooks/useMediaQuery";
import styles from "./VideoPresentation.module.scss";

export const VideoPresentation: React.FC = () => {
  const isTablet = useMediaQuery("tablet");

  return (
    <div className={styles.VideoPresentation}>
      <Text
        textVariant="heading-1"
        as="h1"
        className={styles.VideoPresentation__title}
      >
        Découvrez la balise en action
      </Text>

      <YoutubeContainer
        bordered
        width={isTablet ? "80%" : "90%"}
        ratio={String(560 / 315)}
        src="https://www.youtube.com/embed/t5o-7PewARU"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        className={styles.VideoPresentation__video}
      />
    </div>
  );
};
