import { WizzardExtraStepWrapperProps } from "../../../../components/Wizzard";
import { CarLocalization } from "./sections/CarLocalization/CarLocalization";
import { Presentation } from "./sections/Presentation/Presentation";
import { ProtectNow } from "./sections/ProtectNow/ProtectNow";
import { StillProtection } from "./sections/StillProtection/StillProtection";
import { VideoPresentation } from "./sections/VideoPresentation/VideoPresentation";

const Home: React.FC<WizzardExtraStepWrapperProps> = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Presentation />
      <StillProtection />
      <VideoPresentation />
      <CarLocalization />
      <ProtectNow />
    </div>
  );
};

export default Home;
