import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  StrongLink,
  useWizard,
  WizardNav,
  WizardStep,
} from '@roole/components-library';

import { BankDetailsBlock, HtmlText } from '../../components';
import { SubscriptionStep } from '../SubscriptionWizard/SubscriptionStep';
import { formatCurrency, trackClickHandler } from '../../helpers';
import { useSubscription } from '../../hooks';
import { BankDetails } from '../../models';
import styles from './BankDetailsStep.module.scss';

export const BankDetailsStep: WizardStep = () => {
  const {
    loading,
    updating,
    supportPhoneNumber,
    subscription: { vosCoordonneesBancaires },
    updateBankDetails,
  } = useSubscription();
  const { submitStep } = useWizard();
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<BankDetails>({ mode: 'all' });
  const pending = loading || updating;

  const submitHandler = useCallback(
    (formValues: BankDetails) => {
      if (isValid) {
        submitStep(() =>
          updateBankDetails({
            ...formValues,
            isCompleteFile: true,
          })
        );
      }
    },
    [isValid, submitStep, updateBankDetails]
  );

  const bypassBankDetailsHandler = useCallback(() => {
    trackClickHandler('CoordonneesBancairesNonRenseignees')();
    submitStep(() =>
      updateBankDetails({
        isCompleteFile: false,
      } as BankDetails)
    );
  }, [submitStep, updateBankDetails]);

  return (
    <form onSubmit={handleSubmit(submitHandler)} autoComplete="off" noValidate>
      <SubscriptionStep
        title="Vos coordonnées bancaires"
        subtitle={
          <HtmlText
            text={`<p>Vous avez déjà réglé la 1ère année de votre adhésion lors de l'achat de votre véhicule.</p><br>
        <p>En communiquant votre IBAN, <strong>votre adhésion pourra être reconduite dans 1 an sans aucune démarche de votre part
        </strong> et sera alors réglée par un prélèvement mensuel de <strong>${
          vosCoordonneesBancaires
            ? formatCurrency(vosCoordonneesBancaires.directDebit)
            : 'xx.xx €'
        }</strong>. Vous restez libre de résilier votre contrat à tout moment.</p><br>
        <p>En cas de sinistre vos indemnités seront versées directement sur votre compte bancaire sous 48 heures.<p>`}
            variant="body"
          />
        }
        supportPhoneNumber={supportPhoneNumber}
      >
        {vosCoordonneesBancaires && (
          <BankDetailsBlock
            control={control}
            bankDetails={vosCoordonneesBancaires}
            disabled={pending}
          />
        )}
        <div className={styles['bank-details-step__bypass']}>
          <StrongLink
            onClick={bypassBankDetailsHandler}
            label="Ne pas renseigner mes coordonnées bancaires"
            customAttributes={{ type: 'button' }}
          />
        </div>
        <WizardNav
          nextStepLabel="Valider l'étape 2"
          nextStepDisabled={!isValid}
          nextCustomAttributes={{ type: 'submit' }}
          nextStepHandler={() => ({})}
          disabled={pending}
        />
      </SubscriptionStep>
    </form>
  );
};
