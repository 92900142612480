import { useParams } from "react-router-dom";
import Wizzard, {
  WizardStepStatus,
  WizzardProps,
} from "../../components/Wizzard";
import { RxService } from "../../services/RxService";
import { Finisher, Informations, Payment, Home } from "./steps";

const rxService = RxService.getInstance();

const BaliseRx: React.FC = () => {
  const params = useParams();
  const initWizzard: WizzardProps["initWizzard"] = async () => {
    let startStepIndex: number | "end" | undefined;
    const commandeId = localStorage.getItem("commandeRxId");

    if (!commandeId) {
      startStepIndex = undefined;
      if (params.id) parent.location.pathname = "/balise-rx";
    } else if (params.id) {
      const success =
        (await rxService
          .verifyCommandeStatus(params.id)
          .catch(() => "Invalide")) === "Valide";
      if (success) startStepIndex = "end";
      else if (commandeId) startStepIndex = 2;
    } else if (commandeId) {
      startStepIndex = 2;
    }

    return {
      startStepIndex,
      stepsConfig: [
        {
          step: 0,
          status: WizardStepStatus.STARTED,
          label: "Etape 1",
        },
        {
          step: 1,
          status: WizardStepStatus.UNSTARTED,
          label: "Etape 2",
        },
        {
          step: 2,
          status: WizardStepStatus.UNSTARTED,
          label: "Etape 3",
        },
      ],
    };
  };

  return (
    <Wizzard initWizzard={initWizzard}>
      <Home isAtStart={true} />

      <Informations
        step={0}
        label="Informations"
        // title=" "
        // title="Remplissez vos informations"
        dark
      />

      <Payment
        step={1}
        label="Paiement"
        // title="Récapitulatif de votre commande"
        dark
      />

      <Finisher
        isAtStart={false}
        dark
        centered
        title="Votre véhicule est (presque) protégé !"
      />
    </Wizzard>
  );
};

export default BaliseRx;
