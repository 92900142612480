import { Wizard } from '@roole/components-library';

import { AppRoute } from '../../AppRoute';
import { BankDetailsStep } from '../BankDetailsStep/BankDetailsStep';
import { CongratulationsStep } from '../CongratulationsStep/CongratulationsStep';
import { CongratulationsStepGuard } from '../CongratulationsStep/CongratulationsStepGuard';
import { ESignatureStep } from '../ESignatureStep/ESignatureStep';
import { ESignatureStepGuard } from '../ESignatureStep/ESignatureStepGuard';
import { PersonalInformationStep } from '../PersonalInformationStep/PersonalInformationStep';
import { WelcomeStep } from '../WelcomeStep/WelcomeStep';
import { SubscriptionWizardAddon } from './SubscriptionWizardAddon';

export const SubscriptionWizard = () => (
  <Wizard addon={SubscriptionWizardAddon}>
    <WelcomeStep path={AppRoute.WELCOME} label="Bienvenue" />
    <PersonalInformationStep
      path={AppRoute.PERSONAL_INFORMATIONS}
      label="Vos informations"
    />
    <BankDetailsStep
      path={AppRoute.BANK_DETAILS}
      label="Vos coordonnées bancaires"
    />
    <ESignatureStep
      path={AppRoute.E_SIGNATURE}
      label="Signature du bulletin d'adhésion"
      guard={ESignatureStepGuard}
    />
    <CongratulationsStep
      path={AppRoute.CONGRATULATIONS}
      label="Félicitations"
      guard={CongratulationsStepGuard}
    />
  </Wizard>
);
