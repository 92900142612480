import { Text, WizardNav, WizardStep } from '@roole/components-library';

import { SummaryBlock, BlockField } from '../../components';
import { SubscriptionStep } from '../SubscriptionWizard/SubscriptionStep';
import { formatDate, formatNumber } from '../../helpers';
import { useSubscription } from '../../hooks';
import styles from './PersonalInformationStep.module.scss';

export const PersonalInformationStep: WizardStep = () => {
  const {
    loading,
    supportPhoneNumber,
    subscription: { votreVehicule, vosInformationsPersonnelles },
  } = useSubscription();

  return (
    <SubscriptionStep
      title="Vos informations"
      subtitle={`Vous trouverez ci-dessous le récapitulatif de vos informations saisies lors de l'achat de votre véhicule. 
      Nous vous invitons à les vérifier, les modifier si besoin puis les valider.`}
      supportPhoneNumber={supportPhoneNumber}
    >
      <SummaryBlock
        heading={
          <Text textVariant="heading-4" as="h2">
            Votre véhicule
          </Text>
        }
        pending={loading}
      >
        <BlockField label="Plaque d’immatriculation">
          {votreVehicule?.plaqueImmatriculation}
        </BlockField>
        <BlockField label="Marque et modèle">
          {votreVehicule?.marqueModele}
        </BlockField>
        <BlockField
          className={styles['personal-information-step__item--22']}
          label="Première mise en circulation"
        >
          {formatDate(votreVehicule?.premiereMiseEnCirculation)}
        </BlockField>
        <BlockField
          className={styles['personal-information-step__item--32']}
          label="Nombre de kilomètres au compteur"
        >
          {!!votreVehicule?.nombreKilometresAuCompteur &&
            formatNumber(votreVehicule.nombreKilometresAuCompteur)}
        </BlockField>
      </SummaryBlock>
      <SummaryBlock
        heading={
          <Text textVariant="heading-4" as="h2">
            Vos informations personnelles
          </Text>
        }
        pending={loading}
      >
        <BlockField label="Civilité">
          {vosInformationsPersonnelles?.civilite}
        </BlockField>
        <BlockField label="Prénom et nom">
          {vosInformationsPersonnelles?.prenomNom}
        </BlockField>
        <BlockField label="Email">
          {vosInformationsPersonnelles?.email}
        </BlockField>
        <BlockField
          className={styles['personal-information-step__item--22']}
          label="Adresse"
          readonly
        >
          {vosInformationsPersonnelles?.adresse && (
            <span className="text--capitalize">
              {vosInformationsPersonnelles?.adresse?.toLowerCase()}
            </span>
          )}
        </BlockField>
        <BlockField
          className={styles['personal-information-step__item--32']}
          label="Complément d’adresse"
        >
          {vosInformationsPersonnelles?.complementAdresse}
        </BlockField>
        <BlockField
          className={styles['personal-information-step__item--42']}
          label="Téléphone portable"
        >
          {vosInformationsPersonnelles?.telephonePortable}
        </BlockField>
      </SummaryBlock>
      <WizardNav nextStepLabel="Valider l'étape 1" disabled={loading} />
    </SubscriptionStep>
  );
};
