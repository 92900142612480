import { REACT_APP_ADHESION_API_URL } from "../constants/env";
import { ICommand } from "../models/Command";
import { ApiService } from "./ApiService";

export class RxService {
  private static instance: RxService;
  private readonly api: ApiService;

  private constructor() {
    this.api = new ApiService(REACT_APP_ADHESION_API_URL);
  }

  public static getInstance(): RxService {
    if (!this.instance) {
      this.instance = new RxService();
    }

    return this.instance;
  }

  async makeCommande(commande: ICommand): Promise<string> {
    return this.api
      .post<{ commandeRx: ICommand }>("/Commande", { commandeRx: commande })
      .then((data) => data.toString());
  }
  async getCommande(commandeId: string): Promise<ICommand> {
    return this.api
      .get<ICommand>(`/Commande/${commandeId}`)
      .then((data) => data);
  }
  async confirmCommande(commandeId: string): Promise<{ urlPaiement: string }> {
    return (
      this.api
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        .post<any>(`/Commande/${commandeId}/confirm`, {})
        .then((data) => data)
    );
  }
  async verifyCommandeStatus(commandeId: string): Promise<string> {
    return this.api
      .get<string>(`/Commande/VerifyTransactionStatus/${commandeId}`)
      .then((data) => data);
  }
}
