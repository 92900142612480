import { AuthAction, AuthActionType } from './AuthActions';
import { Subscription } from '../../models';

export interface AuthState {
  subscription: Partial<Subscription>;
  logging: boolean;
  logged: boolean;
  error: string | null;
}

export const initialAuthState: AuthState = {
  subscription: {},
  logging: false,
  logged: false,
  error: null,
};

export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionType.LOGIN:
      return { ...state, logging: true, error: null };
    case AuthActionType.LOGIN_SUCCEEDED:
      return {
        ...state,
        logging: false,
        logged: true,
        subscription: { ...action.details },
      };
    case AuthActionType.LOGIN_FAILED:
      return { ...state, logging: false, error: action.error };
    default:
      return { ...initialAuthState };
  }
};
