import { FC, AnchorHTMLAttributes } from "react";
import { classNames } from "../../helpers";
import styles from "./ExternalLink.module.scss";

interface ExternalLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  id?: string;
}

export const ExternalLink: FC<ExternalLinkProps> = ({
  children,
  className,
  id,
  ...props
}) => (
  <a
    id={id}
    className={classNames(styles.ExternalLink, className)}
    {...props}
    target="_blank"
  >
    {children}
  </a>
);
