import styled, { css, keyframes } from "styled-components";
import { defaultLayout } from "../../../constants/theme/global";
import { mediaQueries, spaces } from "../../../constants/theme/variables";
import mergeStyles from "../../../helpers/mergeStyles";
import { StyledWizzardStepProps } from "./WizzardStep.types";

const wizzardStepAnimation = () => {
  const animation = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
  `;
  return css`
    animation: ${animation};
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  `;
};

export const StyledWizzardStep = styled.article<StyledWizzardStepProps>(
  ({ hasAside }) => {
    const gridConfig = hasAside
      ? {
          display: "grid",
          gridTemplateColumns: "2fr 1fr",
        }
      : {};

    return mergeStyles(defaultLayout, {
      position: "relative",
      height: "100%",

      [`${StyledWizzardBodyStep}`]: {
        [mediaQueries.desktop]: {
          ...gridConfig,
        },
      },
    });
  }
);

export const WrappedStyledWizzardBodyStep = styled.div(() => {
  return mergeStyles(defaultLayout, {
    position: "relative",
    margin: `0 ${spaces.m}`,
    marginBottom: 0,
  });
});
export const StyledWizzardBodyStep = styled(WrappedStyledWizzardBodyStep)`
  ${wizzardStepAnimation}
`;

export const StyledWizzardContentStep: React.FC = ({ children }) => {
  const Content = styled.div(() => {
    return mergeStyles(defaultLayout, {});
  });

  return <Content>{children}</Content>;
};
