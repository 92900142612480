export enum WizardStepStatus {
  UNSTARTED = "UNSTARTED",
  STARTED = "STARTED",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
  LOCKED = "LOCKED",
}

export type StepConfig = {
  step: number;
  label: string;
  status?: WizardStepStatus;
  stickedNav?: boolean;
  stickedHeader?: boolean;
};
export type StepperProps = {
  //   stepsConfig: StepConfig[];
};
