export const REACT_APP_ADHESION_API_URL =
  process.env.REACT_APP_ADHESION_API_URL;
export const REACT_APP_UNIVERSIGN_URL = process.env.REACT_APP_UNIVERSIGN_URL;
export const REACT_APP_GOOGLE_ANALYTICS =
  process.env.REACT_APP_GOOGLE_ANALYTICS;
export const REACT_APP_SITEWEB_VOB2C_URL =
  process.env.REACT_APP_SITEWEB_VOB2C_URL;
export const REACT_APP_GOOGLE_GTM = process.env.REACT_APP_GOOGLE_GTM;
export const REACT_APP_ACCOUNT_URL =
  process.env.REACT_APP_ACCOUNT_URL || "https://moncompte.roole.fr/";

export const REACT_APP_AIDE_ET_CONTACT = "https://aide.roole.fr";
export const REACT_APP_CONDITION_GENERALES_VENTE_URL =
  process.env.NODE_ENV === "production"
    ? "https://stroolepublic.blob.core.windows.net/cgv/CGV_BaliseRx.pdf"
    : "https://stroolepublichp.blob.core.windows.net/cgv/CGV_BaliseRx.pdf";
export const REACT_APP_DROIT_RETRACTATION_URL =
  process.env.NODE_ENV === "production"
    ? "https://stroolepublic.blob.core.windows.net/documents/legal/retractation/RX.pdf"
    : "https://stroolepublichp.blob.core.windows.net/documents/legal/retractation/RX.pdf";
