import { useCallback, useEffect } from 'react';
import {
  Block,
  PortraitLayout,
  PREV_BTN,
  useWizard,
  WizardNav,
  WizardStep,
  WizardStepLayout,
} from '@roole/components-library';

import { useESignature, useMatchMedia, useSubscription } from '../../hooks';
import cssVars from '../../styles/variables.module.scss';

const ESIGNATURE_DOM_ID = 'eSignatureContent';

export const ESignatureStep: WizardStep = () => {
  const {
    loading,
    subscription: { eSignatureOptions },
    eSignatureCompleted,
  } = useSubscription();
  const {
    status,
    pending: signaturePending,
    completed,
    error,
    setup,
  } = useESignature();
  const { submitStep, nextStep, setError } = useWizard();
  const isMobile = useMatchMedia(cssVars.breakpointMobile);
  const pending = loading || signaturePending;
  let description = 'Finalisez pour rouler en toute sérénité !';

  useEffect(() => {
    if (eSignatureOptions) {
      const teardown = setup(ESIGNATURE_DOM_ID, eSignatureOptions.signerId);
      return () => teardown();
    }
  }, [eSignatureOptions]);

  const nextStepHandler = useCallback(
    () =>
      submitStep(
        async () => eSignatureCompleted(status),
        () => nextStep({ replace: true })
      ),
    [status, submitStep, eSignatureCompleted, nextStep]
  );

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error, setError]);

  useEffect(() => {
    if (completed) {
      nextStepHandler();
    }
  }, [completed, nextStepHandler]);

  if (isMobile) {
    description = `${description}<br />Pour pouvoir visualiser votre document en toute sérénité, nous vous recommandons de le télécharger.`;
  }

  return (
    <WizardStepLayout
      title="Signature du bulletin d'adhésion"
      subtitle={description}
    >
      <WizardNav buttons={PREV_BTN} disabled={pending} />
      <Block>
        <PortraitLayout id={ESIGNATURE_DOM_ID} />
      </Block>
      <WizardNav buttons={PREV_BTN} disabled={pending} />
    </WizardStepLayout>
  );
};
