import { Button, Text } from "@roole/components-library";
import { useWizzard } from "../../../../../../components/Wizzard";
import { ProductInfo } from "./ProductInfo/ProductInfo";
import styles from "./Presentation.module.scss";

export const Presentation: React.FC = () => {
  const { nextStep } = useWizzard();
  return (
    <div className={styles.Presentation}>
      <img src="/assets/r-Roole-bg.png" style={{ margin: "auto" }} />

      <div className={styles.Presentation__Presentation}>
        <div className={styles.Presentation__titles}>
          <div>
            <Text
              textVariant="heading-6"
              as="h5"
              className={styles.Presentation__Presentation__info}
            >
              Balise Rx
            </Text>
            <Text textVariant="heading-1" as="h1">
              Protégez votre véhicule contre le vol
            </Text>
            {/* <Text textVariant="heading-2" as="h2">
              49€
            </Text> */}
          </div>
          <div className={styles.Presentation__buyBlock}>
            <Text textVariant="heading-1" as="h2">
              49€
            </Text>

            <Button
              variant="primary"
              iconBeforeText
              icon="arrow-right"
              customAttributes={{
                className: styles.buyBtn,
              }}
              onClick={() => {
                nextStep();
              }}
            >
              <Text textVariant="body-em">Acheter la balise</Text>
            </Button>
          </div>
        </div>

        <Text
          textVariant="body"
          as="p"
          className={styles.Presentation__Presentation__body}
        >
          Dissimulée dans le véhicule, la balise Rx peut être détectée à tout
          moment par les forces de l’ordre et l’ensemble de la communauté Roole.
          <br />
          <br />
          Lorsque le véhicule est déclaré volé, la balise Rx se connecte à un
          vaste réseau permettant de transmettre les informations de
          localisation. La position est partagée immédiatement aux autorités.
        </Text>

        <div className={styles.Presentation__Presentation__infos}>
          <ProductInfo
            title="Identification par les forces de l’ordre"
            content="Détection via l'application Roole Protect"
          />

          <ProductInfo
            title="Géolocalisation communautaire par les membres Roole"
            content="Détection via l’application Roole Premium"
          />

          <ProductInfo
            title="Services connectés"
            content="Ecoconduite: score et conseils de conduite"
          />

          <ProductInfo
            title="Durée de vie"
            content="Balise autonome pendant 3 ans (piles AAA)"
          />

          <ProductInfo
            title="Portée du signal"
            content="Signal détectable dans un rayon de 50 mètres"
          />
        </div>
      </div>
    </div>
  );
};
