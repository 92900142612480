import { Text } from "@roole/components-library";
import styles from "./ProductInfo.module.scss";

type ProductInfoProps = {
  title: string;
  content: string;
};
export const ProductInfo: React.FC<ProductInfoProps> = ({ title, content }) => {
  return (
    <div className={styles.ProductInfo}>
      <Text
        textVariant="caption-data"
        as="p"
        className={styles.ProductInfo__title}
      >
        {title}
      </Text>
      <Text
        textVariant="caption"
        as="p"
        className={styles.ProductInfo__content}
      >
        {content}
      </Text>
    </div>
  );
};
