import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Text, InputText, Block } from '@roole/components-library';

import { BlockField } from '../BlockField/BlockField';
import { BankDetails } from '../../models';
import { InputIBAN } from '../InputIBAN/InputIBAN';
import { classNames, CommonProps, validateIBan } from '../../helpers';

interface BankDetailsBlockProps extends CommonProps {
  control: Control<BankDetails>;
  bankDetails?: BankDetails;
  disabled?: boolean;
}
const formRulesValidators = {
  holder: {
    required: { value: true, message: 'Ce champ est obligatoire' },
    minLength: {
      value: 3,
      message: 'Ce champ doit contenir au moins 3 charactères',
    },
    maxLength: {
      value: 256,
      message: 'Ce champ doit contenir moins de 256 charactères',
    },
  },

  iban: {
    required: { value: true, message: 'Ce champ est obligatoire' },
    validate: {
      iban: (value: string) =>
        validateIBan(value) || 'Ce champ doit être saisi au bon format',
    },
  },
};

export const BankDetailsBlock: FC<BankDetailsBlockProps> = ({
  className,
  control,
  bankDetails,
  disabled = false,
  ...props
}) => {
  const { holder, iban } = bankDetails || {};

  return (
    <Block className={classNames(className)} {...props}>
      <Text textVariant="body" as="p">
        Veuillez entrer vos informations de paiement ci-dessous
      </Text>
      <Controller
        name="holder"
        control={control}
        rules={formRulesValidators.holder}
        defaultValue={holder}
        render={({ field, fieldState: { error } }) => (
          <BlockField label="Titulaire du compte" inline>
            <InputText
              placeholder="Votre nom et prénom"
              label="Prénom Nom *"
              error={error?.message}
              disabled={disabled}
              required
              minLength={3}
              maxLength={256}
              {...field}
            />
          </BlockField>
        )}
      />
      <Controller
        name="iban"
        control={control}
        rules={formRulesValidators.iban}
        defaultValue={iban}
        render={({ field, fieldState: { error } }) => {
          const inputRef = error?.ref as HTMLInputElement;
          inputRef?.setCustomValidity(
            error?.type === 'iban' ? 'iban error' : ''
          );
          return (
            <BlockField label="IBAN" inline>
              <InputIBAN
                label="Numéro d'IBAN *"
                placeholder="Ex : FR74 XXXX XXXX XXXX XXXX XXXX XXX"
                error={error?.message}
                disabled={disabled}
                required
                {...field}
              />
            </BlockField>
          );
        }}
      />
    </Block>
  );
};
