import { AppRoute } from "../AppRoute";
import {
  REACT_APP_ACCOUNT_URL,
  REACT_APP_DROIT_RETRACTATION_URL,
  REACT_APP_CONDITION_GENERALES_VENTE_URL,
  REACT_APP_AIDE_ET_CONTACT,
} from "../constants/env";

interface AppConfig {
  header: {
    publicWebsiteURL: string;
  };
  footer: {
    oriasURL: string;
    privacyPolicyURL: string;
    legalNoticesURL: string;
    cookiesURL: string;
    baliseRx: {
      aideEtContact: string;
      conditionsGeneralesVenteURL: string;
      droitRetractation: string;
    };
  };
  support: {
    phoneNumber: string;
    accountURL: string;
  };
}

export const DEFAULT_CONFIG: AppConfig = {
  header: {
    publicWebsiteURL: `/${AppRoute.WELCOME}`,
  },
  footer: {
    oriasURL: "https://orias.fr",
    privacyPolicyURL: "https://www.roole.fr/charte-de-confidentialite/",
    legalNoticesURL: "https://www.roole.fr/mentions-legales/",
    cookiesURL: "https://www.roole.fr/cookies/",
    baliseRx: {
      aideEtContact: REACT_APP_AIDE_ET_CONTACT,
      conditionsGeneralesVenteURL: REACT_APP_CONDITION_GENERALES_VENTE_URL,
      droitRetractation: REACT_APP_DROIT_RETRACTATION_URL,
    },
  },
  support: {
    phoneNumber: "01 46 90 37 37",
    accountURL: REACT_APP_ACCOUNT_URL,
  },
};
