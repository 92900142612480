import { FC } from 'react';

import { Auth } from './Auth';
import { useAuth } from '../../hooks';

export const AuthGuard: FC = ({ children }) => {
  const { logged } = useAuth();

  return logged ? <>{children}</> : <Auth />;
};
