import { ComponentProps, FC, Fragment } from 'react';
import { Text } from '@roole/components-library';

import { AccordionsBlock } from '../AccordionsBlock/AccordionsBlock';
import { BusinessDetailsGroup } from '../../models';

interface BlockGroupProps {
  blocks?: BusinessDetailsGroup[];
  variant?: ComponentProps<typeof AccordionsBlock>['variant'];
}

export const BlockGroup: FC<BlockGroupProps> = ({ blocks, variant }) => {
  return (
    <Fragment>
      {blocks?.map((block, index) => (
        <AccordionsBlock
          key={`block-${index}`}
          heading={
            <Text textVariant="heading-4" as="h2">
              {block.title}
            </Text>
          }
          items={block.items}
          variant={variant}
        />
      ))}
    </Fragment>
  );
};
