import {
  Children,
  CSSProperties,
  useState,
  useEffect,
  ReactElement,
} from "react";
import {
  StepConfig,
  WizardStepStatus,
} from "./WizzardStepper/WizzardStepper.types";
import { WizzardProvider } from "./Wizzard.contexte";
import { WizzardProps } from "./Wizzard.types";
import WizzardHeader from "./WizzardHeader";
import { NavBtnsConfig } from "./WizzardNav";
import { OverlayLoader } from "@roole/components-library";
import _styles from "./Wizzard.module.scss";
import { classNames } from "../../helpers";

const Wizzard: React.FC<WizzardProps> = ({
  children,
  className,
  initWizzard,
}) => {
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsConfig, setStepsConfig] = useState<StepConfig[]>([]);
  const [navBtnsConfig, setNavBtnsConfig] = useState<NavBtnsConfig>({});

  const elements = Children.toArray(children) as ReactElement[];
  const stepsAtStart = elements.filter((child) => {
    return typeof child.props.isAtStart === "boolean" && child.props.isAtStart;
  }).length;
  const steps = elements.filter((child) => {
    return typeof child.props.isAtStart !== "boolean";
  });
  const stepsAtEnd = elements.filter((child) => {
    return typeof child.props.isAtStart === "boolean" && !child.props.isAtStart;
  }).length;

  const countSteps = elements.length - stepsAtStart - stepsAtEnd;

  const goToStep = (step: number | "home" | "finisher", stepper?: boolean) => {
    if (typeof step === "number" && step >= elements.length) return;

    let toStep: number;

    if (typeof step !== "number")
      toStep = step === "home" ? 0 : stepsAtStart + steps.length;
    else if (stepper) {
      toStep = stepsAtStart + step;
    } else toStep = step;

    setCurrentStep(toStep);
  };

  const changeStatus = (step: number, value: WizardStepStatus) => {
    step;
    value;
  };

  useEffect(() => {
    initWizzard()
      .then((res) => {
        setStepsConfig(
          steps.map((step, index) => ({
            step: step.props.step,
            label: step.props.label,
            stickedNav: !!elements[stepsAtStart + index].props.stickedNav,
            stickedHeader: !!elements[stepsAtStart + index].props.stickedHeader,
          }))
        );

        if (res.startStepIndex === "end")
          setCurrentStep(stepsAtStart + countSteps);
        else
          setCurrentStep(
            res.startStepIndex ? stepsAtStart + res.startStepIndex - 1 : 0
          );
      })
      .finally(() => {
        setInitializing(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentStep]);

  const stepType: "home" | "step" | "finisher" =
    currentStep < stepsAtStart
      ? "home"
      : currentStep < stepsAtStart + steps.length
      ? "step"
      : "finisher";
  const isDark = !!elements[currentStep].props.dark;
  const centered = !!elements[currentStep].props.centered;
  const styles = useStyles(stepType === "step", centered);

  return (
    <div
      style={{
        flexGrow: 1,
        backgroundColor: isDark ? "black" : undefined,
        height: "100%",
        display: "flex",
      }}
    >
      {initializing ? (
        <OverlayLoader
          className={classNames(
            _styles.Loader,
            loading && _styles.Loader__loading
          )}
        />
      ) : (
        <>
          {loading && (
            <OverlayLoader
              className={classNames(
                _styles.Loader,
                loading && _styles.Loader__loading
              )}
            />
          )}
          {
            <WizzardProvider
              value={{
                loading,
                dark: isDark,
                step: stepType === "step" ? currentStep - stepsAtStart : -1,
                currentStep,
                countSteps,
                navBtnsConfig,
                setLoading,
                goToStep,
                nextStep: () => goToStep(currentStep + 1),
                prevStep: () => goToStep(currentStep - 1),
                setNavBtnsConfig: (_navBtnsConfig) =>
                  setNavBtnsConfig(_navBtnsConfig),
                changeStatus,
                stepsConfig,
              }}
            >
              <div className={className} style={styles.wizzard}>
                <WizzardHeader
                  title={elements[currentStep].props.title}
                  subtitle={elements[currentStep].props.subtitle}
                  subtitleTxt={elements[currentStep].props.subtitleTxt}
                  isStep={stepType === "step"}
                />

                {elements[currentStep]}
              </div>
            </WizzardProvider>
          }
        </>
      )}
    </div>
  );
};

const useStyles = (isStep: boolean, centered: boolean) => ({
  wizzard: {
    height: "100%",
    ...(isStep
      ? { maxWidth: 1136, marginLeft: "auto", marginRight: "auto" }
      : {}),
    ...(centered
      ? { maxWidth: 1136, marginLeft: "auto", marginRight: "auto" }
      : {}),
  } as CSSProperties,
});

export default Wizzard;
