import { Block, Text } from "@roole/components-library";
import { classNames } from "../../../helpers";
import styles from "./RxProduct.module.scss";

type RxProductProps = {
  className?: string;
  withBuyBtn?: boolean;
  onClickBuy?: () => void;
  headband?: boolean;
};

export const RxProduct: React.FC<RxProductProps> = ({
  className,
  headband,
}) => {
  return (
    <Block
      className={classNames(
        styles.RxProduct,
        styles[`RxProduct--${headband ? "headband" : "not-headband"}`],
        className
      )}
    >
      <div className={styles.priceAndImg}>
        <img src="/assets/r-Roole-bg.png" />

        <div className={styles.priceInfos}>
          <Text textVariant="display-1">
            49 €<Text textVariant="caption-data"></Text>
          </Text>

          <Text textVariant="body">Taxes et frais de port inclus</Text>
        </div>
      </div>

      <Text textVariant="caption-data" className={styles.deliveryInfos}>
        Livraison sous 15 jours,
        <br />
        uniquement en France métropolitaine
      </Text>
    </Block>
  );
};
