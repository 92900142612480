import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export class ApiService {
  protected readonly _httpClient: AxiosInstance;

  constructor(baseURL?: string) {
    this._httpClient = axios.create({ baseURL });
  }

  get httpClient(): AxiosInstance {
    return this._httpClient;
  }

  get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.httpClient.get<T>(url, config).then(this.mapResponse);
  }

  post<T>(url: string, data: T, config?: AxiosRequestConfig): Promise<T> {
    return this.httpClient.post<T>(url, data, config).then(this.mapResponse);
  }

  put<T>(url: string, data: T, config?: AxiosRequestConfig): Promise<T> {
    return this.httpClient.put<T>(url, data, config).then(this.mapResponse);
  }

  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.httpClient.delete<T>(url, config).then(this.mapResponse);
  }

  protected mapResponse<T>(response: AxiosResponse<T>): T {
    return response.data;
  }
}
