import { WizardAside, WizardStepLayout } from '@roole/components-library';
import { ComponentProps, FC, ReactNode } from 'react';

import { QuestionBlock } from '../../components';
import { useMatchMedia } from '../../hooks';
import cssVars from '../../styles/variables.module.scss';

interface SubscriptionStepProps
  extends ComponentProps<typeof WizardStepLayout> {
  aside?: ReactNode;
  supportPhoneNumber?: string;
}

export const SubscriptionStep: FC<SubscriptionStepProps> = ({
  children,
  aside,
  supportPhoneNumber,
  ...props
}) => {
  const isDesktop = useMatchMedia(cssVars.breakpointDesktop);

  return (
    <WizardStepLayout {...props}>
      {children}
      {(isDesktop || !!aside) && (
        <WizardAside>
          {isDesktop && <QuestionBlock phoneNumber={supportPhoneNumber} />}
          {aside}
        </WizardAside>
      )}
    </WizardStepLayout>
  );
};
