import { FC, Fragment, useEffect } from 'react';
import { useWizard } from '@roole/components-library';

import { QuestionBlock } from '../../components';
import { useMatchMedia, useSubscription } from '../../hooks';
import { scrollIntoView } from '../../helpers';
import cssVars from '../../styles/variables.module.scss';

export const SubscriptionWizardAddon: FC = ({ children }) => {
  const { supportPhoneNumber, getStepDetails } = useSubscription();
  const { activeStep, setError } = useWizard();
  const isDesktop =
    useMatchMedia(cssVars.breakpointDesktop) && activeStep?.index !== 3; // Not ESignatureStep

  useEffect(() => {
    const activeStepIndex = activeStep?.index;

    if (activeStepIndex !== undefined && activeStepIndex !== -1) {
      scrollIntoView('rootHeader');
      getStepDetails(activeStepIndex).catch((err) => setError(err));
    }
  }, [activeStep?.index, getStepDetails]);

  return (
    <Fragment>
      {!isDesktop && <QuestionBlock phoneNumber={supportPhoneNumber} dark />}
      {children}
    </Fragment>
  );
};
