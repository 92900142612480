import StyledWizzardContent, {
  StyledWizzardAsideContent,
} from "./WizzardContent.styled";

export type WizzardContentProps = {
  className?: string;
  aside?: boolean;
};
const WizzardContent: React.FC<WizzardContentProps> = ({
  children,
  className,
  aside,
}) => {
  const Wrapper = aside ? StyledWizzardAsideContent : StyledWizzardContent;

  return <Wrapper className={className}>{children}</Wrapper>;
};

export default WizzardContent;
