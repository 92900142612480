interface PushParams {
  event: string;
  data?: object;
}

declare global {
  interface Window {
    dataLayer?: PushParams[];
  }
}

interface AnalyticsEvent {
  event: string;
  "gtm.elementId": string;
}

// https://developers.google.com/tag-platform/tag-manager/web/datalayer
const push = (event: AnalyticsEvent) => {
  window?.dataLayer?.push(event);
};

export const trackEvent = (elementId: string, event: string) =>
  push({ event, "gtm.elementId": elementId });

const analytics = {
  trackEvent,
  trackClick: (elementId: string) => trackEvent(elementId, "gtm.click"),
};

export const trackClickHandler = (elementId: string) => () =>
  analytics.trackClick(elementId);

export const pushRxEvent = (event: {
  category: string;
  action: string;
  label: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  value: any;
}) => {
  window?.dataLayer?.push({
    event: "event",
    data: {
      category: event.category,
      action: event.action,
      label: event.label,
      value: event.value,
    },
  });
};
