import styled from "styled-components";

export const StepperWrapper = styled.div((_) => {
  _;

  return {
    display: "flex",
    justifyContent: "space-between",
  };
});

export const Step = styled(
  styled.div<{ done: boolean }>((_) => {
    _;

    return {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: 1,
      flexGrow: 1,
      lineHeight: 1,
      margin: 0,
    };
  })
)`
  :before {
    position: absolute;
    content: "";
    border-bottom: 10px solid #ccc;
    width: 100%;
    top: 3px;
    left: -50%;
    z-index: 2;
  }

  :after {
    position: absolute;
    content: "";
    border-bottom: 10px solid ${(props) => (props.done ? "#FFD100" : "#ccc")};
    width: ${(props) => (props.done ? 100 : 0)}%;
    top: 3px;
    left: 50%;
    z-index: ${(props) => (props.done ? 3 : 2)};
    transition: 1.2s;
  }

  :first-child::before,
  :last-child::after {
    content: none;
  }
`;

export const StepPoint = styled.div<{ done: boolean }>(({ done }) => {
  return {
    // cursor: "pointer",
    position: "relative",
    zIndex: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 16,
    height: 16,
    borderRadius: "50%",
    marginBottom: 6,
    transition: "1s",
    backgroundColor: done ? "#FFD100" : "white",
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: done ? "#FFD100" : "#757575",
  };
});
