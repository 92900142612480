export const IbanTypes: Record<string, string> = {
  DE: "^DE\\d{20}$",
  FR: "^FR\\d{12}[0-9A-Z]{11}\\d{2}$",
  AT: "^AT\\d{18}$",
  BE: "^BE\\d{14}$",
  CY: "^CY\\d{10}[0-9A-Z]{16}$",
  ES: "^ES\\d{22}$",
  EE: "^EE\\d{18}$",
  FI: "^FI\\d{16}$",
  GP: "^FR\\d{12}[0-9A-Z]{11}\\d{2}$",
  GF: "^FR\\d{12}[0-9A-Z]{11}\\d{2}$",
  MQ: "^FR\\d{12}[0-9A-Z]{11}\\d{2}$",
  RE: "^FR\\d{12}[0-9A-Z]{11}\\d{2}$",
  YT: "^FR\\d{12}[0-9A-Z]{11}\\d{2}$",
  PM: "^FR\\d{12}[0-9A-Z]{11}\\d{2}$",
  MC: "^MC\\d{12}[A-Z0-9]{11}\\d{2}$",
  GR: "^GR\\d{9}[0-9A-Z]{16}$",
  IE: "^IE\\d{2}[A-Z]{4}\\d{14}$",
  IT: "^IT\\d{2}[A-Z]\\d{10}[0-9A-Z]{12}$",
  LV: "^LV\\d{2}[A-Z]{4}[0-9A-Z]{13}$",
  LT: "^LT\\d{18}$",
  LU: "^LU\\d{5}[0-9A-Z]{13}$",
  MT: "^MT\\d{2}[A-Z]{4}\\d{5}[0-9A-Z]{18}$",
  NL: "^NL\\d{2}[A-Z]{4}\\d{10}$",
  PT: "^PT\\d{23}$",
  SK: "^SK\\d{22}$",
  SI: "^SI\\d{17}$",
  GI: "^GI\\d{2}[A-Z]{4}[0-9A-Z]{15}$",
  AD: "^AD\\d{10}[A-Z0-9]{12}$",
  CH: "^CH\\d{7}[A-Z0-9]{12}$",
  CZ: "^CZ\\d{22}$",
  RO: "^RO\\d{2}[A-Z]{4}[A-Z0-9]{16}$",
  SE: "^SE\\d{22}$",
  SM: "^SM\\d{2}[A-Z]{1}\\d{10}[A-Z0-9]{12}$",
  LI: "^LI\\d{7}[A-Z0-9]{12}$",
  HR: "^HR\\d{19}$",
  DK: "^DK\\d{16}$",
  HU: "^HU\\d{26}$",
  IS: "^IS\\d{24}$",
  NO: "^NO\\d{13}$",
  PL: "^PL\\d{26}$",
  VA: "^VA\\d{20}$",
  AL: "^AL\\d{10}[A-Z0-9]{16}$",
  BG: "^BG\\d{2}[A-Z]{4}\\d{6}[A-Z0-9]{8}$",
  RS: "^RS\\d{20}$",
  GB: "^GB\\d{2}[A-Z]{4}\\d{14}$",
};
/**
 * Algo to check if the IBAN is valid
 * @param iban string
 * @returns valid iban
 */
const isValidIBAN = (iban: string) => {
  const rearranged = iban.substring(4, iban.length) + iban.substring(0, 4);
  const numeric = Array.from(rearranged)
    .map((c) => (isNaN(parseInt(c)) ? (c.charCodeAt(0) - 55).toString() : c))
    .join("");
  return (
    Array.from(numeric)
      .map((c) => parseInt(c))
      .reduce((remainder, value) => (remainder * 10 + value) % 97, 0) === 1
  );
};
export const validateIBan = (textValue: string) => {
  const validIBAN = textValue.replace(/\s/g, "");
  if (!validIBAN) {
    return false;
  }
  const ibanRegex = IbanTypes[validIBAN.slice(0, 2)];
  if (ibanRegex) {
    if (new RegExp(ibanRegex).test(validIBAN)) {
      return isValidIBAN(validIBAN);
    }
    return false;
  }
  return false;
};