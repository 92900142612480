import { createContext, FC, useCallback, useReducer } from 'react';

import { AuthActionType } from './AuthActions';
import { initialAuthState, authReducer } from './authReducer';
import { AuthService } from '../../services';
import { Subscription } from '../../models';

interface AuthContextProps {
  subscription: Partial<Subscription>;
  logging: boolean;
  logged: boolean;
  error: string | null;
  login(token: string | null): Promise<Partial<Subscription> | string>;
}

export const initialAuthContextProps: AuthContextProps = {
  ...initialAuthState,
  login: async () => 'error',
};

export const AuthContext = createContext<AuthContextProps>({
  ...initialAuthContextProps,
});

export const AuthContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    ...initialAuthState,
  });
  const service = AuthService.getInstance();

  const loginHandler = useCallback(async (token: string | null) => {
    dispatch({ type: AuthActionType.LOGIN });

    try {
      const details = await service.login(token);
      dispatch({
        type: AuthActionType.LOGIN_SUCCEEDED,
        details,
      });
      return details;
    } catch (err) {
      const error = err as string;
      dispatch({
        type: AuthActionType.LOGIN_FAILED,
        error,
      });
      return error;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login: loginHandler,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
