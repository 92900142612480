import { createContext, useContext } from "react";
import { NavBtnsConfig } from "./WizzardNav";
import {
  StepConfig,
  WizardStepStatus,
} from "./WizzardStepper/WizzardStepper.types";

export interface IWizzardContext {
  loading: boolean;
  dark: boolean;
  step: number;
  currentStep: number;
  countSteps: number;
  stepsConfig: StepConfig[];
  navBtnsConfig: NavBtnsConfig;
  setLoading(isLoading: boolean): void;
  goToStep(step: number, stepper?: boolean): void;
  nextStep(): void;
  prevStep(): void;
  setNavBtnsConfig: (navBtnsConfig: NavBtnsConfig) => void;
  changeStatus(step: number, value: WizardStepStatus): void;
}
export const initialWizzardContext = {
  loading: false,
  dark: false,
  step: 0,
  currentStep: 0,
  countSteps: 0,
  stepConfig: { title: "" },
  navBtnsConfig: {},
};
const wizzardContext = createContext<IWizzardContext>({
  ...initialWizzardContext,
  stepsConfig: [],
  setLoading: (isLoading) => {
    isLoading;
  },
  goToStep: (step: number, stepper?: boolean) => {
    step;
    stepper;
  },
  nextStep: () => 0,
  prevStep: () => 0,
  setNavBtnsConfig: (_) => _,
  changeStatus: (step, value) => {
    step;
    value;
  },
});

export const useWizzard = () => {
  const context = useContext(wizzardContext);

  return context;
};
export const WizzardProvider: React.FC<{ value: IWizzardContext }> = ({
  children,
  value,
}) => {
  return (
    <wizzardContext.Provider value={value}>{children}</wizzardContext.Provider>
  );
};
